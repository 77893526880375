import React, { useRef } from 'react';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import NvFroala from 'froala/components/nv-froala';
import { AngularServicesContext } from 'react-app';
import { editQuizQuestion } from 'redux/actions/quizzes';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { isEmpty } from 'underscore';
import { QuizQuestionType } from 'redux/schemas/models/progressive-quiz';
import { config } from '../../../config/pendo.config.json';

type Props = {
  className?: string;
};

const defaultQuestionTemplate = '<p class="froala-style-medium"><br /></p>';

const QuizQuestionContent = (props: Props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const mountedRef = useRef(false);
  const {
    mode,
    setIsQuizSaving,
    setShowSavingContainer,
    setIsSaved,
    timeoutRef,
  } = React.useContext(ProgressiveQuizContext);
  const { currentQuestion } = React.useContext(QuestionContext);
  const angularServices = React.useContext(AngularServicesContext);
  const { registerSaving } = React.useContext(SavingRegistryContext);
  const [value, setValue] = React.useState(currentQuestion.questionText || defaultQuestionTemplate);
  const isStatement = currentQuestion.type === QuizQuestionType.STATEMENT;

  React.useEffect(() => {
    if (mountedRef.current) {
      const patch = {
        questionText: value,
      };

      const unregister = registerSaving();
      setShowSavingContainer(true);
      setIsQuizSaving(true);
      clearTimeout(timeoutRef.current);

      dispatch(editQuizQuestion({
        patch,
        id: currentQuestion.id,
      })).then((res) => {
        setIsQuizSaving(false);
        if (isEmpty(res.error)) {
          setIsSaved(true);
        } else {
          setIsSaved(false);
        }
      }).finally(() => {
        unregister();
        timeoutRef.current = setTimeout(() => {
          setShowSavingContainer(false);
        }, 2000);
      });
    }
  }, [
    dispatch,
    value,
    currentQuestion.id,
    registerSaving,
    setShowSavingContainer,
    setIsQuizSaving,
    setIsSaved,
    timeoutRef,
  ]);

  React.useEffect(() => {
    mountedRef.current = true;
  }, []);

  if (mode === ProgressiveQuizMode.EDIT) {
    return (
      <NvFroala
        allowToolbar
        value={value}
        minHeight={80}
        className={className}
        onChange={(html) => setValue(html)}
        placeholder={isStatement ? t.QUIZZES.TYPE_STATEMENT() : t.LECTURE_PAGES.COMPONENTS.POLLS.TITLE_PLACEHOLDER()}
        dataQa={config.pendo.activities.progressiveQuiz.questionModal.questionTitle}
        dataQaId={`${config.pendo.activities.progressiveQuiz.questionModal.questionTitle}_${currentQuestion.id}`}
      />
    );
  }

  return (
    <ResponsivelyEmbeddedAngularHTML
      className={className}
      angularServices={angularServices}
      template={currentQuestion.questionText}
    />
  );
};

export default QuizQuestionContent;
