import { css } from '@emotion/react';
import t from 'react-translate';
import { doubleSpacing, halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { ReactComponent as StarBiggest } from 'styles/icons/star-biggest.svg';
import { ReactComponent as StarBig } from 'styles/icons/star-big.svg';
import { ReactComponent as StarMedium } from 'styles/icons/star-medium.svg';
import { ReactComponent as StarSmall } from 'styles/icons/star-small.svg';

const IconSizes = {
  BIGGEST: 50,
  BIG: 12,
  MEDIUM: 8,
  SMALL: 6,
};

const NvGeneratingLoader = () => {
  const generatingAiStyles = css`
    margin: ${doubleSpacing}px 0px;
    .icon {
      align-content: center;
    }
    span {
      padding: ${halfSpacing}px;
    }

    /* The styles below were given directly from design team, and they were just adapted to the code */

    .text-ai-color {
      background: linear-gradient(
        to right,
        #60f3ab 20%,
        #14d1c2 40%,
        #60f3ab 60%,
        #14d1c2 80%
      );
      background-size: 200% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      animation: shine 3s linear infinite;
    }
    .lds-grid{
      margin: 0px auto ${halfSpacing}px;
      width: 60px;
      height: 60px;
      .star {
        animation: blink 2.2s linear infinite;
      }
      .biggest{
        left: 0px;
        bottom: 0px;
        width: ${IconSizes.BIGGEST}px;
        height: ${IconSizes.BIGGEST}px;
      }
      .big{
        right: ${halfSpacing}px;
        top: ${halfSpacing}px;
        width: ${IconSizes.BIG}px;
        height: ${IconSizes.BIG}px;
      }
      .medium{
        top: 0px;
        width: ${IconSizes.MEDIUM}px;
        height: ${IconSizes.MEDIUM}px;
      }
      .small{
        top: ${quarterSpacing}px;
        right: 0px;
        width: ${IconSizes.SMALL}px;
        height: ${IconSizes.SMALL}px;
      }
    }
    @keyframes shine {
      to {
        background-position: 200% center;
      }
    }
    @keyframes blink {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.2;
      }
    }
  `;

  /**
   * All the svg were created by design team and addapted here as they required.
   */
  return (
    <div className='justify-content-center text-center' css={generatingAiStyles}>
      <div className='lds-grid position-relative'>
        <StarBiggest className='star biggest position-absolute' />
        <StarBig className='star big position-absolute' />
        <StarMedium className='star medium position-absolute' />
        <StarSmall className='star small position-absolute' />
      </div>
      <span className='font-weight-bolder text-large-regular text-ai-color'>
        {t.LECTURE_PAGES.COMPONENTS.GENERATING()}
      </span>
    </div>
  );
};

export default NvGeneratingLoader;
