import React from 'react';
import { useAppDispatch } from 'redux/store';
import { reorderLectureComponent } from 'redux/actions/lecture-components';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import ClickableContainer from 'components/clickable-container';
import { useLecturePageMainPanel } from 'lecture_pages/components/lecture-page-content';
import { BaseLectureComponentProps, getLectureComponentDomId } from './base-lecture-component';
import usePostRender from 'shared/hooks/use-post-render';

const ComponentReorderButtons = (props: {
  lectureComponent: BaseLectureComponentProps['lectureComponent'],
  currentLecturePage: BaseLectureComponentProps['currentLecturePage'],
}) => {
  const lecturePageMainPanel = useLecturePageMainPanel();
  const params = useLecturePageParams();
  const dispatch = useAppDispatch();

  const scheduleScroll = usePostRender(() => {
    // Using angular-scroll logic for scroll since for some reason
    // "scrollIntoView" doesn't properly scroll to desired element in some cases
    // https://github.com/oblador/angular-scroll/blob/ce0b3e6bfd0d7fff98702cdb8dc83998ed32861a/src/helpers.js#L38
    // (That's how it was being done in old lecture page code: https://github.com/novoed/NovoEdWeb/blob/f0d17b1e86ce6a8832a71ce05edcdc5ca961be9b/app/lecture_pages/directives/nv-component-reorder.js#L37)
    const $lectureComponent = $('#' + getLectureComponentDomId(props.lectureComponent));
    ($(lecturePageMainPanel) as any).scrollToElementAnimated($lectureComponent, 40, 400);
  }, []);

  const reorderComponent = (newIndex: number) => {
    dispatch(reorderLectureComponent({
      catalogId: params.catalogId,
      lecturePageId: props.lectureComponent.lecturePageId,
      lectureComponent: props.lectureComponent,
      newIndex,
    }));
    scheduleScroll();
  };

  return (
    <React.Fragment>
      <ComponentMoveButton
        direction='up'
        componentIndex={props.lectureComponent.index}
        componentListLength={props.currentLecturePage.lectureComponents.length}
        fireReorder={() => reorderComponent(props.lectureComponent.index - 1)}
      />
      <ComponentMoveButton
        direction='down'
        componentIndex={props.lectureComponent.index}
        componentListLength={props.currentLecturePage.lectureComponents.length}
        fireReorder={() => reorderComponent(props.lectureComponent.index + 1)}
      />
    </React.Fragment>
  );
};

const ComponentMoveButton = (props: {
  direction: 'up' | 'down',
  fireReorder: () => void,
  componentIndex: number,
  componentListLength: number,
}) => {
  const isActive = (props.direction === 'up' && props.componentIndex > 0) || (props.direction === 'down' && props.componentIndex < props.componentListLength - 1);

  return (
    <ClickableContainer
      disabled={!isActive}
      className={`icon icon-xss-smallest icon-move-${props.direction} ${isActive ? 'active' : ''}`}
      onClick={props.fireReorder}
    />
  );
};

export default ComponentReorderButtons;
