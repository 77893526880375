import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { values, difference } from 'underscore';
import { css } from '@emotion/react';
import t from 'react-translate';
import skillTags from 'redux/reducers/skill-tags';
import { SubmissionTab } from 'redux/schemas/models/video-practice';
import { RootState } from 'redux/schemas';
import { authorRatingsSorted } from 'redux/selectors/skills-feedback';
import { getSubmission } from 'redux/selectors/video-practice';
import { NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import { useQuery } from 'shared/hooks/use-query';
import { tripleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../../../config/pendo.config.json';
import { ActionTypes, PracticeSubmissionContext, Tab, selectedViewToIndex } from '../utils';

const styles = css`
  &.sticky-tabs {
    position: sticky;
    top: -5px;
    background: white;
    z-index: 10;
  }
  .tab {
    height: auto !important;
    min-height: ${tripleSpacing}px;
    padding-top: ${halfSpacing}px;
    padding-bottom: ${halfSpacing}px;
  }
`;

const LOAD_VIDEO_PREVIEW = 1500;

const PracticeSubmissionTabs = () => {
  const [{
    submissionId,
    isPracticeFeedback,
    isPracticeRoom,
    isCourseAdmin,
    selectedView,
    showCommentButton,
    isAdmin,
    isMyPractice,
  }, practiceSubmissionDispatch] = useContext(PracticeSubmissionContext);
  const {
    activity,
    isRatedByCurrentUser,
    isViewerMentor,
    hasInsightsEnabled,
  } = useSelector((state) => getSubmission(state, submissionId));
  const authorRatings = useSelector(state => authorRatingsSorted(state, submissionId, 'videoPracticeSubmissions'));
  const query = useQuery();
  const tabRef = useRef<HTMLDivElement>(null);
  const hasSkillsRating = useSelector((state: RootState) => {
    if (activity) {
      return activity.hasSkillsRating ?? state.models.practiceActivities[activity.id]?.hasSkillsRating;
    }
    return false;
  });
  const {
    submissionId: selectedSubmissionId,
    selectedSubmissionTab,
  } = useSelector((state) => state.app.practiceRoom.params);

  const onSelectTab = useCallback((tab: SubmissionTab) => {
    practiceSubmissionDispatch({
      type: ActionTypes.SET_SELECTED_VIEW,
      payload: tab,
    });
  }, [practiceSubmissionDispatch]);

  useEffect(() => {
    if (submissionId === selectedSubmissionId && selectedSubmissionTab) {
      onSelectTab(selectedSubmissionTab);
    }
  }, [onSelectTab, selectedSubmissionId, selectedSubmissionTab, submissionId]);

  const tabs: Tab[] = [
    {
      text: t.PRACTICE_ROOM.SUBMISSION.TABS.COMMENTS(),
      onClick: () => onSelectTab(SubmissionTab.COMMENTS),
      pendoTagName: config.pendo.skillsFeedback.practiceTabComments,
      tabValue: SubmissionTab.COMMENTS,
    },
  ];

  if (!isPracticeFeedback) {
    if (!isMyPractice && skillTags?.length > 0
      && ((isPracticeRoom && (authorRatings?.length > 0 || isRatedByCurrentUser))
        || (!isPracticeRoom && (isRatedByCurrentUser || isCourseAdmin))
      )
    ) {
      tabs.push({
        text: t.PRACTICE_ROOM.SUBMISSION.TABS.YOUR_SKILLS_FEEDBACK(),
        onClick: () => onSelectTab(SubmissionTab.AUTHOR_FEEDBACK),
        pendoTagName: config.pendo.skillsFeedback.practiceTabYourSkillsFeedback,
        tabValue: SubmissionTab.AUTHOR_FEEDBACK,
      });
    }
    if (isMyPractice || isCourseAdmin || isViewerMentor) {
      if (skillTags?.length > 0 && hasSkillsRating) {
        tabs.push({
          text: t.PRACTICE_ROOM.SUBMISSION.TABS.ALL_SKILLS_FEEDBACK(),
          onClick: () => onSelectTab(SubmissionTab.ALL_FEEDBACK),
          pendoTagName: config.pendo.skillsFeedback.practiceTabAllSkillsFeedback,
          tabValue: SubmissionTab.ALL_FEEDBACK,
        });
      }

      if (hasInsightsEnabled) {
        tabs.push({
          text: t.PRACTICE_ROOM.SUBMISSION.TABS.INSIGHTS(),
          onClick: () => onSelectTab(SubmissionTab.INSIGHTS),
          dataQA: 'automated-feedback-insights-tab',
          pendoTagName: config.pendo.practice.insightsTab,
          tabValue: SubmissionTab.INSIGHTS,
        });
      }
    }
  }

  useEffect(() => {
    const showInstantInsights = query.submissionView === SubmissionTab.INSIGHTS && query.stickyInsights === 'true';

    if (showInstantInsights) {
      // Get the hash from the url and scroll to the element id  id={`tabs-submission-${submissionId}`}
      if (tabRef?.current?.id?.includes(selectedSubmissionId)) {
        // Wait the video Preview to load and then scroll to the element
        setTimeout(() => {
          tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, LOAD_VIDEO_PREVIEW);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.submission, query.submissionView, query.stickyInsights]);

  const getActiveTabIndex = useCallback(() => {
    const allTabs = values(SubmissionTab);
    const availableTabs = tabs.map(tab => tab.tabValue);
    const missingTabs = difference(allTabs, availableTabs);
    const tabsBeforeActiveTab = missingTabs.filter(
      tab => selectedViewToIndex(tab) < selectedViewToIndex(selectedView),
    );
    return selectedViewToIndex(selectedView) - tabsBeforeActiveTab.length;
  }, [selectedView]);

  const showTabs = (showCommentButton || isAdmin) && tabs.length > 1;

  return (
    <div
      className={`submission-tabs-row border-bottom border-gray-6 mb-4${
        query.stickyInsights === 'true' ? ' sticky-tabs' : ''
      }`}
      css={styles}
      ref={tabRef}
      id={`tabs-submission-${submissionId}`}
    >
      {showTabs && tabs.length > 1 && (
        <NvResponsiveTabsRow
          defaultTabs={tabs}
          tabTextClass='card-title'
          tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
          revertActiveTab={getActiveTabIndex()}
        />
      )}
    </div>
  );
};

export default PracticeSubmissionTabs;
