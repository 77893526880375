import React, {} from 'react';
import { NQuizQuestion } from 'redux/schemas/models/progressive-quiz';

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion;
  className?: string;
};
const ShortAnswerQuizQuestion = (props: QuizQuestionProps) => (
  <div>
    {/* TO DO short Answer */}
  </div>
);

export default ShortAnswerQuizQuestion;
