import { RolesService } from 'institutions/services/roles-service';

/* @ngInject */
export default function TeamWorkspaceMainController(
  $q,
  $scope,
  $state,
  $stateParams,
  $timeout,
  $uibModal,
  TeamManager,
  ConfirmationOverlays,
  CurrentCourseManager,
  CurrentUserManager,
  DiscussionsManager,
  FlyOutPanelManager,
  InteroperabilityRoutes,
  TeamWorkspaceManager,
  _,
  config,
) {
  const vm = this;
  vm.$stateParams = $stateParams;
  vm.TeamWorkspaceManager = TeamWorkspaceManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  vm.$state = $state;
  vm.config = config;
  vm.lastMemberInView = lastMemberInView;
  vm.toggleShowLess = toggleShowLess;
  vm.launchMeetingModal = launchMeetingModal;
  vm.currentUserManager = CurrentUserManager;
  vm.launchMeetingRsvpModal = launchMeetingRsvpModal;
  vm.launchDocumentUploadModal = launchDocumentUploadModal;
  vm.launchShareSubmissionModal = launchShareSubmissionModal;
  vm.launchWorkspaceContributionsModal = launchWorkspaceContributionsModal;
  vm.selectTab = selectTab;
  vm.googleDriveCallback = null;
  vm.requestingTeamWorkspace = true;
  vm.openFullscreenWorkspace = openFullscreenWorkspace;
  vm.currentTeamCourse = CurrentUserManager.getCurrentCourse();
  vm.shouldHideButtons = shouldHideButtons;

  initialize();

  function initialize() {
    vm.googleDriveCallback = $state.params.googleDriveOpen;
    vm.selectedTab = 'Activities';
    vm.loadingWorkspacePosts = true;
    vm.shownMembers = { num: 7 };
    vm.shownTodos = { num: 3 };
    vm.shownDrafts = { num: 1 };
    vm.shownSubmissions = { num: 1 };
    vm.showLess = true;
    vm.requestingTeamWorkspace = true;

    const promises = [];

    promises.push(CurrentUserManager.requestAndUpdateCourse($stateParams.catalogId));
    promises.push(TeamWorkspaceManager.requestTeam($stateParams.catalogId, $stateParams.teamId));
    return $q.all(promises).then((response) => {
      vm.requestingTeamWorkspace = false;
      vm.isNonMember = !vm.TeamWorkspaceManager.currentTeam.currentAccountTeamMember || !TeamWorkspaceManager.currentTeam.currentAccountTeamMember.approved;

      const isCourseAdmin = CurrentUserManager.isInstructorForCurrentCourse() || CurrentUserManager.isTeachingAssistantForCurrentCourse();
      if (!isCourseAdmin && vm.isNonMember) {
        ConfirmationOverlays.openConfirmationModal(
          'team_workspace/templates/team-workspace-permissions-error-overlay.html',
          'AttachModalResolvesToVmCtrl as vm',
          {
            vmResolves() {
              return {
                course: CurrentUserManager.getCurrentCourse(),
                currentTeam: TeamWorkspaceManager.currentTeam,
              };
            },
          },
        ).result.then(() => {
          $state.go('team-profile-page', { catalogId: $stateParams.catalogId, teamId: $stateParams.teamId, mobileapp: $stateParams.mobileapp });
        });
      } else {
        vm.settingsTooltipText = (vm.TeamWorkspaceManager.currentTeam.currentAccountTeamMember?.isAdmin || isCourseAdmin) ? 'TEAMS.WORKSPACE.RHS.ADMIN_SETTINGS_TOOLTIP' : 'TEAMS.WORKSPACE.RHS.MEMBER_SETTINGS_TOOLTIP';

        TeamWorkspaceManager.currentTeam.getTodos();
        TeamWorkspaceManager.currentTeam.getDrafts();
        TeamWorkspaceManager.currentTeam.getSubmissions();
        TeamWorkspaceManager.setupPusherEvents();

        TeamManager.markWorkspaceAsVisited(TeamWorkspaceManager.currentTeam.id);

        // need $state.params instead of $stateParams because $stateParams only has the parent state's params for some reason
        if ($state.params.postId) {
          TeamWorkspaceManager.setContext('workspaceDirectLink');
          TeamWorkspaceManager.fetchSingleWorkspacePost($state.params.postId, true);
          vm.directLinkInfo = $state.params;
        } else {
          TeamWorkspaceManager.setContext('workspace');
          TeamWorkspaceManager.fetchAllWorkspacePosts();
          TeamWorkspaceManager.getWorkspaceFilterCounts();
        }

        if (vm.googleDriveCallback) {
          launchDocumentUploadModal();
        }
      }
    }, (error) => {
      ConfirmationOverlays.openConfirmationModal('team_workspace/templates/team-workspace-not-exist-error-overlay.html')
        .result.then(() => {
          FlyOutPanelManager.closeFlyoutPanelCallback();
        });
    });
  }

  // Main
  function selectTab(tab) {
    vm.selectedTab = tab;
    $timeout(() => {
      angular.element(window).trigger('checkInView');
    }, 100);
  }

  function openFullscreenWorkspace() {
    const url = $state.href(
      'team-workspace-full',
      { catalogId: $state.params.catalogId, teamId: $state.params.teamId },
    );
    FlyOutPanelManager.closeFlyoutPanelCallback();
    window.open(url, '_blank');
  }

  function lastMemberInView(inView) {
    vm.isLastMemberInView = inView;
  }

  function toggleShowLess(showLess) {
    vm.showLess = showLess;
    $timeout(() => {
      angular.element(window).trigger('checkInView');
    }, 100);
  }

  function launchMeetingModal() {
    const modal = $uibModal.open({
      templateUrl: 'team_workspace/templates/team-workspace-new-meeting-modal.html',
      controller: 'WorkspaceMeetingModalInstanceController as vm',
      windowClass: 'full-screen-modal-handheld team-workspace-new-meeting-modal',
      resolve: {
        existingMeeting: null,
      },
    });

    modal.result.then(afterPostCreate);
  }

  function launchDocumentUploadModal() {
    const modal = $uibModal.open({
      templateUrl: 'team_workspace/templates/document-upload-modal.html',
      controller: 'DocumentWorkspacePostModalInstanceController as vm',
      windowClass: 'full-screen-modal-handheld',
      resolve: {
        existingWorkspacePost: null,
      },
    });

    modal.result.then(
      afterPostCreate,
      () => {
        if (vm.googleDriveCallback) {
          $state.go($state.$current, { googleDriveOpen: undefined }, { notify: false });
        }
      },
    );
  }

  function launchShareSubmissionModal() {
    const modal = $uibModal.open({
      templateUrl: 'team_workspace/templates/team-workspace-share-submission-modal.html',
      controller: 'TeamWorkspaceShareSubmissionModalInstanceController as vm',
      windowClass: 'full-screen-modal-handheld full-screen-modal-tablet team-workspace-share-submission-modal',
      resolve: {
        existingSubmissionPost: null,
      },
    });

    modal.result.then(afterPostCreate);
  }

  function launchMeetingRsvpModal(meeting) {
    const modal = $uibModal.open({
      templateUrl: 'team_workspace/templates/team-workspace-meeting-rsvp-modal.html',
      controller: 'MeetingRsvpModalInstanceController as vm',
      windowClass: 'full-screen-modal-handheld team-workspace-rsvp-modal',
      resolve: {
        rsvpStatus() {
          if (!meeting.attendance.length || vm.isNonMember) {
            return null;
          }

          const currentUserRsvp = _.findWhere(meeting.attendance, { teamMemberId: TeamWorkspaceManager.currentTeam.currentAccountTeamMember.id });
          return currentUserRsvp ? currentUserRsvp.rsvp : null;
        },
        meeting() {
          return _.clone(meeting);
        },
      },
    });

    modal.result.then((rsvp) => {
      afterPostCreate(meeting, rsvp);
    });
  }

  function afterPostCreate(meeting, rsvp) {
    if (rsvp?.newRsvp) {
      meeting.addMeetingAttendance(rsvp.rsvpData);
    }

    if (vm.directLinkInfo) {
      // if in direct link mode, redirect
      $state.go('team-workspace');
    }
  }

  // RHS
  function launchWorkspaceContributionsModal(memberId) {
    TeamWorkspaceManager.fetchWorkspaceContributions(memberId).then((result) => {
      $uibModal.open({
        templateUrl: 'team_workspace/templates/workspace-contribution-modal.html',
        windowClass: 'contribution-modal',
        scope: $scope,
      });
    });
  }

  function shouldHideButtons() {
    return vm.currentTeamCourse.inArchiveMode
    && (RolesService.isMentor(vm.currentTeamCourse.userCourse?.roles)
    || RolesService.isLearner(vm.currentTeamCourse.userCourse?.roles));
  }

  $scope.$on('$destroy', () => {
    TeamWorkspaceManager.unsubscribePusherEvents();
    TeamWorkspaceManager.resetTeam();

    // Initialize with previous param on destroying team workspace and the new
    // state doesn't include team-workspace aka closing team workspace
    if (DiscussionsManager?.preModalParams?.catalogId && !$state.includes('team-workspace')) {
      if ($state.params?.catalogId && DiscussionsManager?.preModalParams?.catalogId === $state.params.catalogId) {
        DiscussionsManager.initialize(DiscussionsManager.preModalParams);
        DiscussionsManager.initializeMentions();
      }
      DiscussionsManager.resetPreModalParams();
    }
  });

  $scope.$on('$stateChangeSuccess', () => {
    // Initialize Discussion Manager and team mentionable After state change success.
    // Reset discussion manager when closing individual submission modal
    if ($state.includes('team-workspace')) {
      if (DiscussionsManager.catalogId !== $state.params.catalogId || DiscussionsManager.context !== 'teamWorkspace') {
        DiscussionsManager.initialize({ catalogId: $state.params.catalogId, context: 'teamWorkspace' });
        if (TeamWorkspaceManager.currentTeam) {
          TeamWorkspaceManager.initializeTeamMentionables($state.params.catalogId);
        }
      }
    }
  });
}
