import { css } from '@emotion/react';
import omit from 'lodash/omit';
import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import { OverlayTrigger } from 'react-bootstrap';
import t from 'react-translate';
import NvSwitch from 'shared/components/inputs/nv-switch';
import NvTooltip from 'shared/components/nv-tooltip';
import useClickOutside from 'shared/hooks/use-click-outside';
import { mergeRefs } from 'shared/react-utils';
import { useAppDispatch } from 'redux/store';
import { translateJourney, translateLecturePage, translateOutline, unsetTranslationPreference } from 'redux/actions/users';
import { getFullCourseTimelineContent } from 'redux/actions/timeline';
import { getCourseFontColor, getCurrentCourse } from 'redux/selectors/course';
import { getCurrentUser } from 'redux/selectors/users';
import { gray5, gray2, white, primary, gray4, black, gray1 } from 'styles/global_defaults/colors';
import { popoverZIndex, standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import LanguagePicker, { AUTOMATED_TRANSLATION_LANGUAGES, LanguageValue } from 'automated_translation/components/language-picker';
import useLanguageTranslation from 'automated_translation/hooks/use-language-translation';
import ClickableContainer from 'components/clickable-container';
import useAngularExpression from 'shared/hooks/use-angular-expression';
import useScrollTop from 'shared/hooks/use-scroll';
import { config } from '../../../config/pendo.config.json';


const AutomatedTranslationMenu = () => {
  const dispatch = useAppDispatch();
  const getLanguageTranslation = useLanguageTranslation();

  const currentUser = useSelector(getCurrentUser);
  const { currentCatalogId, lecturePage: { currentLectureId, isTranslating } } = useSelector((state) => state.app);
  const courseFontColor = useSelector(getCourseFontColor);
  const isTopHeaderScrolling = useScrollTop(document.getElementById('main-panel'));

  const { injectServices } = useContext(AngularContext);
  const [$state, CurrentUserManager] = injectServices(['$state', 'CurrentUserManager']);
  const currentStateName = useAngularExpression(() => $state.current.name);
  const isJourneyHome = currentStateName === 'learning-journey-home';
  const isCourseHome = currentStateName === 'course-home';
  const isLecturePage = currentStateName === 'lecture-page';

  const hasTranslationSet = !!currentUser.translationPreferenceLanguage;
  const [autoTranslationIsOn, setAutoTranslationIsOn] = useState(hasTranslationSet);
  const [showOverlay, setShowOverlay] = useState(false);
  const buttonRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setAutoTranslationIsOn(!!currentUser.translationPreferenceLanguage);
  }, [currentUser.translationPreferenceLanguage]);


  const lastTranslation = currentUser.lastTranslationPreferenceLanguage ?? currentUser.translationPreferenceLanguage;

  const initialLanguageValue = lastTranslation ? {
    isoCode: lastTranslation,
    label: getLanguageTranslation(AUTOMATED_TRANSLATION_LANGUAGES.find((language) => language.isoCode === lastTranslation)?.translationKey),
  } : null;

  const [language, setLanguage] = useState<LanguageValue>(initialLanguageValue);

  const applyTranslation = (turnOn, newLanguage) => {
    setAutoTranslationIsOn(turnOn);

    if (isCourseHome) {
      if (turnOn) {
        dispatch(translateOutline({
          language: newLanguage.isoCode,
          catalogId: currentCatalogId,
        })).then(() => {
          dispatch(getFullCourseTimelineContent({
            catalogId: currentCatalogId,
          }));
          CurrentUserManager.user.updateFromReact({
            translationPreferenceLanguage: newLanguage.isoCode,
            lastTranslationPreferenceLanguage: newLanguage.isoCode,
          });
        });
      } else {
        dispatch(unsetTranslationPreference()).then(() => {
          CurrentUserManager.user.updateFromReact({
            translationPreferenceLanguage: null,
          });
        }).then(() => {
          dispatch(getFullCourseTimelineContent({
            catalogId: currentCatalogId,
          }));
        });
      }
    } else if (isLecturePage) {
      if (turnOn) {
        dispatch(translateLecturePage({
          language: newLanguage.isoCode,
          catalogId: currentCatalogId,
          lecturePageId: currentLectureId,
        })).then(() => {
          dispatch(translateOutline({
            language: newLanguage.isoCode,
            catalogId: currentCatalogId,
          }));
        }).then(() => {
          CurrentUserManager.user.updateFromReact({
            translationPreferenceLanguage: newLanguage.isoCode,
            lastTranslationPreferenceLanguage: newLanguage.isoCode,
          });
        });
      } else {
        dispatch(unsetTranslationPreference()).then(() => {
          CurrentUserManager.user.updateFromReact({
            translationPreferenceLanguage: null,
          });
        });
      }
    } else if (isJourneyHome) {
      if (turnOn) {
        dispatch(translateJourney({
          language: newLanguage.isoCode,
          catalogId: currentCatalogId,
        })).then(() => {
          CurrentUserManager.user.updateFromReact({
            translationPreferenceLanguage: newLanguage.isoCode,
            lastTranslationPreferenceLanguage: newLanguage.isoCode,
          });
        });
      } else {
        dispatch(unsetTranslationPreference()).then(() => {
          CurrentUserManager.user.updateFromReact({
            translationPreferenceLanguage: null,
          });
        });
      }
    }
  };

  const selectLanguage = (lang) => {
    setLanguage(lang);

    if (lang && autoTranslationIsOn && currentUser.translationPreferenceLanguage !== lang.isoCode) {
      applyTranslation(true, lang);
    }
  };

  // These are the default styles.
  // They should be used for lesson page, when autoTranslation is NOT on
  // Or, when the course font color is black (or we're scrolling) and autoTranslation is NOT on
  let iconColor = black;
  let backgroundColor;
  let borderColor = black;

  if (isCourseHome && !isTopHeaderScrolling && courseFontColor === '#FFF') {
    if (autoTranslationIsOn) {
      iconColor = primary;
      backgroundColor = white;
      borderColor = white;
    } else {
      iconColor = white;
      borderColor = white;
    }
  }
  if (isCourseHome && (isTopHeaderScrolling || courseFontColor !== '#FFF')) {
    if (autoTranslationIsOn) {
      iconColor = white;
      backgroundColor = gray1;
      borderColor = gray1;
    }
  }
  if (isLecturePage && autoTranslationIsOn) {
    iconColor = white;
    backgroundColor = primary;
    borderColor = primary;
  }
  if (isJourneyHome && autoTranslationIsOn && courseFontColor !== '#FFF') {
    iconColor = white;
    backgroundColor = primary;
    borderColor = primary;
  }
  if (!isLecturePage && !isCourseHome && !isJourneyHome) {
    iconColor = gray4;
    borderColor = gray4;
  }

  const iconStyles = css`
    .icon-wrapper {
      color: ${iconColor};
      padding: ${halfSpacing}px;
      padding-top: 13px;
      padding-bottom: 7px;
    }

    .icon-translate {
      padding: 3px;
      border: 2px solid ${borderColor};
      border-radius: 4px;
      font-size: 14px;

      ${backgroundColor && css`
        background-color: ${backgroundColor};
      `}
    }
  `;


  if (!isLecturePage && !isCourseHome && !isJourneyHome) {
    return (
      <ClickableContainer
        disabled
        css={iconStyles}
        data-qa={config.pendo.automatedTranslation.automatedTranslationButton}
        aria-label={t.AUTOMATED_TRANSLATION.AUTO_TRANSLATE()}
      >
        <div className='pb-1'>
          <NvTooltip
            placement='bottom'
            text={t.AUTOMATED_TRANSLATION.AUTO_TRANSLATION_NOT_AVAILABLE()}
          >
            <div className='icon-wrapper pl-2'>
              <i className='icon icon-small icon-translate' />
            </div>
          </NvTooltip>
        </div>
      </ClickableContainer>
    );
  }


  return (
    <OverlayTrigger
      show={showOverlay}
      placement='bottom-end'
      transition={false}
      overlay={(props) => (
        <AccessibleOverlay
          onClose={(closedWithKeyboard) => {
            setShowOverlay(false);

            if (closedWithKeyboard) {
              buttonRef.current.focus();
            }
          }}
          {...omit(props, ['popper', 'arrowProps']) as any}
        >
          <div className='d-flex justify-content-between mb-4'>
            <div>{t.AUTOMATED_TRANSLATION.TURN_ON()}</div>
            {language ? (
              <NvSwitch
                enabledIcon='gen-ai'
                checked={autoTranslationIsOn}
                onChange={(change) => applyTranslation(change, language)}
                disabled={isTranslating}
                data-qa={config.pendo.automatedTranslation.applyTranslationSwitch}
              />
            ) : (
              <NvTooltip text={t.AUTOMATED_TRANSLATION.PLEASE_SELECT_A_LANGUAGE_FIRST()}>
                <NvSwitch
                  enabledIcon='gen-ai'
                  checked={autoTranslationIsOn}
                  onChange={(change) => applyTranslation(change, language)}
                  disabled
                  data-qa={config.pendo.automatedTranslation.applyTranslationSwitch}
                />
              </NvTooltip>
            )}
          </div>
          <div className='label mb-1'>{t.AUTOMATED_TRANSLATION.SELECT_A_LANGUAGE_FOR_AUTO_TRANSLATION()}</div>
          <LanguagePicker
            value={language}
            onChange={(lang) => selectLanguage(lang)}
            dataQa={config.pendo.automatedTranslation.languagesPicker}
          />
        </AccessibleOverlay>
      )}
    >
      {({ ref, ...props }) => (
        <ClickableContainer
          css={iconStyles}
          ref={mergeRefs(ref, buttonRef)}
          onClick={() => setShowOverlay(true)}
          data-qa={config.pendo.automatedTranslation.automatedTranslationButton}
          aria-label={t.AUTOMATED_TRANSLATION.AUTO_TRANSLATE()}
          aria-haspopup
          aria-expanded={showOverlay}
          {...props}
        >
          <div className='pb-1'>
            <NvTooltip
              text={autoTranslationIsOn ? t.AUTOMATED_TRANSLATION.CLICK_TO_TURN_OFF_THE_TRANSLATION() : t.AUTOMATED_TRANSLATION.AUTO_TRANSLATE()}
              placement='bottom'
            >
              <div className='icon-wrapper'>
                <i className='icon icon-small icon-translate' />
              </div>
            </NvTooltip>
          </div>
        </ClickableContainer>
      )}
    </OverlayTrigger>
  );
};

type AccessibleOverlayProps = Omit<React.ComponentProps<'div'>, 'tabIndex' | 'className'> & {
  show: boolean,
  onClose: (closedWithKeyboard: boolean) => void,
};

const AccessibleOverlay = forwardRef<HTMLDivElement, AccessibleOverlayProps>((props, ref) => {
  const {
    show,
    onClose,
    ...restProps
  } = props;

  const innerRef = useRef<HTMLDivElement>();
  const onCloseRef = useRef<Function>();
  onCloseRef.current = onClose;

  const styles = css`
    width: 340px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid ${gray5};
    z-index: ${popoverZIndex};
    padding: ${standardSpacing}px;

    .label {
      color: ${gray2};
    }
  `;


  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.focus();
    }
  }, [show]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose(true);
    }
  };

  useClickOutside(innerRef, () => onClose(false));

  return (
    <div
      css={styles}
      tabIndex={-1}
      className='react-app'
      onKeyDown={handleKeyDown}
      ref={mergeRefs(ref, innerRef)}
      {...restProps}
    />
  );
});

export default AutomatedTranslationMenu;
