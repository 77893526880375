import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';

import t from 'react-translate';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { getAllFeedback, getSummary, deleteFeedback } from 'redux/actions/skills-feedback';
import { SkillsFeedbackRequest } from 'redux/schemas/api/skills-feedback';
import { SubmissionTab } from 'redux/schemas/models/video-practice';
import { authorRatingsSorted } from 'redux/selectors/skills-feedback';
import { getScenario, getSubmission } from 'redux/selectors/video-practice';
import { getCurrentUserId } from 'redux/selectors/users';

import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import AuthorFeedback from 'shared/components/skill-feedback/author-feedback';
import DisplaySkillRating from 'shared/components/skill-feedback/display-skill-ratings';
import RatingSummary, { FEEDBACK_PAGE_SIZE } from 'shared/components/skill-feedback/rating-summary';
import FeedbackForm from './feedback-form';
import { ActionTypes, PracticeSubmissionContext } from '../shared/utils';

const FeedbackTabs = () => {
  const [{
    submissionId,
    scenarioId,
    isPracticeRoom,
    isCourseAdmin,
    selectedView,
    showFeedbackForm,
    isSkillsFeedbackActivity,
  }, practiceSubmissionDispatch] = useContext(PracticeSubmissionContext);
  const {
    activity,
    user,
    isRatedByCurrentUser,
  } = useSelector((state) => getSubmission(state, submissionId));
  const {
    isPracticeAdmin,
    hasCourseAdmin,
  } = useSelector((state) => getScenario(state, scenarioId));
  const {
    feedbackOnceLoaded,
    summary,
    summaryOnceLoaded,
    authorFeedback,
    feedbackLoading,
    feedbackByUserOnceLoaded,
    ratingsByUser,
  } = useSelector((state) => state.app.videoPracticeSubmissions[submissionId]) ?? {};
  const currentInstitutionId = useSelector(
    (state) => state.app.currentInstitutionId,
  );
  const authorRatings = useSelector(state => authorRatingsSorted(state, submissionId, 'videoPracticeSubmissions'));
  const hasSkillsRating = useSelector((state: RootState) => {
    if (activity) {
      return activity.hasSkillsRating ?? state.models.practiceActivities[activity.id]?.hasSkillsRating;
    }
    return false;
  });
  const {
    submissionId: selectedSubmissionId,
    feedbackBy,
  } = useSelector((state) => state.app.practiceRoom.params);
  const { practiceFeedbackCriteriaId } = useSelector(state => state.app.videoPracticeFeedback);
  const currentUserId = useSelector(getCurrentUserId);
  const ratingIds = (ratingsByUser && feedbackBy && ratingsByUser[feedbackBy]) ? ratingsByUser[feedbackBy] : [];

  const isAdmin = (isCourseAdmin || isPracticeAdmin) && isSkillsFeedbackActivity;

  const dispatch = useAppDispatch();
  const [showFeedbackByUser, setShowFeedbackByUser] = useState(false);

  const onGetSkillsFeedback = useCallback((
    skillTaggingId?: number,
    afterId?: number,
  ) => {
    if (submissionId) {
      if (
        selectedView === SubmissionTab.AUTHOR_FEEDBACK
        || skillTaggingId
        || (isPracticeRoom && selectedView !== SubmissionTab.ALL_FEEDBACK)) {
        const params: SkillsFeedbackRequest = {
          institutionId: currentInstitutionId,
          ownerId: submissionId,
          ownerType: 'VideoPracticeSubmission',
          skillTaggingId,
          displayOwnFeedback: selectedView === SubmissionTab.AUTHOR_FEEDBACK,
        };
        if (skillTaggingId) {
          params.pageSize = FEEDBACK_PAGE_SIZE;
          params.afterId = afterId ?? null;
        }
        if (isSkillsFeedbackActivity) {
          params.feedbackCriteriaId = practiceFeedbackCriteriaId;
        }
        dispatch(getAllFeedback(params));
      } else if (selectedView === SubmissionTab.ALL_FEEDBACK) {
        dispatch(getSummary({
          institutionId: currentInstitutionId,
          ownerId: submissionId,
          ownerType: 'VideoPracticeSubmission',
        }));
      }
    }
  }, [
    currentInstitutionId, dispatch, isPracticeRoom, isSkillsFeedbackActivity,
    practiceFeedbackCriteriaId, selectedView, submissionId,
  ]);

  const getFeedbackByUser = useCallback(() => {
    const params: SkillsFeedbackRequest = {
      institutionId: currentInstitutionId,
      ownerId: submissionId,
      ownerType: 'VideoPracticeSubmission',
      displayOwnFeedback: true,
      userId: feedbackBy,
      feedbackCriteriaId: isSkillsFeedbackActivity ? practiceFeedbackCriteriaId : null,
    };
    dispatch(getAllFeedback(params));
  }, [
    currentInstitutionId, submissionId, feedbackBy, isSkillsFeedbackActivity,
    practiceFeedbackCriteriaId, dispatch,
  ]);

  const deleteSkillsFeedback = useCallback((userId) => {
    dispatch(deleteFeedback({
      institutionId: currentInstitutionId,
      ownerId: submissionId,
      ownerType: 'VideoPracticeSubmission',
      userId,
      feedbackCriteriaId: practiceFeedbackCriteriaId,
    }));
    const hideForm = userId !== currentUserId && isCourseAdmin;
    if (!hideForm) {
      practiceSubmissionDispatch({ type: ActionTypes.SET_SHOW_FEEDBACK_FORM, payload: true });
    }
  }, [
    currentInstitutionId, currentUserId, dispatch, isCourseAdmin,
    practiceFeedbackCriteriaId, practiceSubmissionDispatch, submissionId,
  ]);

  const onDeleteFeedback = (userId?: number) => {
    const warningTitle = userId
      ? t.PRACTICE_ROOM.SKILL_FEEDBACK.DELETE_AS_ADMIN.TITLE()
      : t.PRACTICE_ROOM.SKILL_FEEDBACK.DELETE_AS_AUTHOR();
    const desc = userId ? t.PRACTICE_ROOM.SKILL_FEEDBACK.DELETE_AS_ADMIN.DESCRIPTION() : '';
    dispatch(openConfirmationDialog({
      onConfirm: () => deleteSkillsFeedback(userId ?? currentUserId),
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      title: warningTitle,
      bodyText: desc,
    }));
  };

  const toggleShowFeedbackByUser = () => {
    setShowFeedbackByUser(displayFeedbackByUser => !displayFeedbackByUser);
  };


  useEffect(() => {
    if ((submissionId && showFeedbackByUser && selectedView === SubmissionTab.ALL_FEEDBACK) || isSkillsFeedbackActivity) {
      getFeedbackByUser();
    }
  }, [getFeedbackByUser, submissionId, showFeedbackByUser, selectedView]);

  useEffect(() => {
    if ((selectedView === SubmissionTab.ALL_FEEDBACK && !showFeedbackByUser) || hasSkillsRating) {
      onGetSkillsFeedback();
    }
  }, [hasSkillsRating, onGetSkillsFeedback, selectedView, showFeedbackByUser]);

  useEffect(() => {
    if (
      isRatedByCurrentUser
      && (selectedView === SubmissionTab.AUTHOR_FEEDBACK || isPracticeRoom)
      && !authorFeedback?.ratings?.length
      && !feedbackLoading) {
      onGetSkillsFeedback();
    }
  }, [
    authorFeedback?.ratings?.length, feedbackLoading,
    isRatedByCurrentUser, onGetSkillsFeedback, selectedView,
  ]);

  useEffect(() => {
    setShowFeedbackByUser(!!(feedbackBy && (submissionId === selectedSubmissionId)));
  }, [feedbackBy, selectedSubmissionId, submissionId]);

  return (
    <div className={`${showFeedbackByUser ? 'my-3' : 'my-6'}`}>
      {selectedView === SubmissionTab.AUTHOR_FEEDBACK && (
        <LoadingWrapper
          isLoaded={feedbackOnceLoaded || !isRatedByCurrentUser}
          loaderType={LoaderType.PLACEHOLDER}
        >
          {authorRatings?.length > 0 && !showFeedbackForm && (
            <AuthorFeedback
              ownerId={submissionId}
              ownerKey='videoPracticeSubmissions'
              submittedUserName={user.firstName}
              createdAt={authorFeedback?.createdAt}
              onDelete={onDeleteFeedback}
              showTag
            />
          )}
          {isAdmin && (
            <FeedbackForm />
          )}
        </LoadingWrapper>
      )}
      {selectedView === SubmissionTab.ALL_FEEDBACK && (showFeedbackByUser && submissionId === selectedSubmissionId ? (
        <DisplaySkillRating
          isLoaded={feedbackByUserOnceLoaded}
          ratingIds={ratingIds}
          onToggle={toggleShowFeedbackByUser}
        />
      ) : (
        <LoadingWrapper
          isLoaded={summaryOnceLoaded}
          loaderType={LoaderType.PLACEHOLDER}
        >
          {summary?.map(skill => (
            <RatingSummary
              key={skill.skillTagId}
              skillTagId={skill.skillTagId}
              skillRating={skill.avg}
              totalRatings={skill.totalRatings}
              submissionId={submissionId}
              onGetSkillsFeedback={(afterId) => onGetSkillsFeedback(
                skill.skillTaggingId, afterId,
              )}
              skillTaggingId={skill.skillTaggingId}
              onDeleteFeedback={isPracticeRoom ? null : onDeleteFeedback}
              canDelete={hasCourseAdmin}
              ownerKey='videoPracticeSubmissions'
            />
          ))}
          {!isPracticeAdmin && (
            <div className='text-small gray-3 ml-2 mt-6 '>
              {t.SKILLS_FEEDBACK.FEEDBACK_VISIBILITY.ALL()}
            </div>
          )}
        </LoadingWrapper>
      ))}
    </div>
  );
};

export default FeedbackTabs;
