// https://support.jwplayer.com/customer/portal/articles/1413089-javascript-api-reference#setup
export enum JWPLAYER_EVENT_NAMES {
  READY = 'ready', // Fired when when the video player is ready.
  PLAY = 'play', // Fired when the player enters the playing state.
  PAUSE = 'pause', // Fired when the player enters the paused state.
  COMPLETE = 'complete', // Fired when an item completes playback.
  TIME = 'time', // While the player is playing, this event is fired as the playback position gets updated. This may occur as frequently as 10 times per second.
}

/* eslint-disable global-require */
/* @ngInject */
export default function NvJwplayer(
  VideosManager,
  LectureVideoModel,
  jwplayer,
  $timeout,
  _,
  nvUtil,
  $translate,
  config,
) {
  return {
    scope: {
      videoUrl: '=?',
      downloadUrl: '=?',
      video: '=?',
      startOffset: '=?',
      autostart: '=?',
      multiSpeed: '=?',
      playerWidth: '=?',
      playerHeight: '=?',
      aspectRatio: '=?',
      downloadable: '=?',
      fileName: '=?',
      timeCallback: '&?',
      eventCallback: '&?', // will passback { event: 'play', second: 5 }
      endCallback: '&?',
      cuePoints: '=?', // this should be a set of numbers that are percentages of the entire video
      registerSeekToFunction: '&?',
      registerPlayFunction: '&?',
      readyCallback: '&?',
      isAudio: '=?',
      thumbnailUrl: '=?',
    },
    templateUrl: 'shared/templates/nv-jwplayer.html',
    link(scope, element, attrs) {
      let playerInstance;
      let hasOtherQualityUrls = false;
      scope.playerId = scope.playerId || `jwplayer-${nvUtil.randomPositiveInteger()}`;
      scope.multiSpeed = scope.multiSpeed === undefined ? true : scope.multiSpeed;
      scope.hasPlayed = false;

      function setup() {
        // put in protection because angular seems to initiate the directive before the page is completed resulting in a double rerender and removing the first instance
        $timeout(() => {
          const videoSettings: Record<string, any> = {
            autostart: !!scope.autostart,
            flashplayer: scope.video?.videoPlayerUrl,
            playbackRateControls: scope.multiSpeed,
          };

          // Configures an alternate base path for skins and providers - defaults to cdn so need to specify since we're self-hosting
          const jwplayerPath = '/libs/jwplayer8115/';
          if (process.env.NODE_ENV === 'production') {
            videoSettings.base = `${config.assets.host}${jwplayerPath}`;
          } else {
            videoSettings.base = `/origami${jwplayerPath}`;
          }

          videoSettings.width = '100%';
          if (scope.isAudio) {
            videoSettings.height = 40;
            videoSettings.aspectratio = ''; // this is required for audio
          } else {
            videoSettings.aspectratio = scope.aspectRatio || '16:9';
          }

          if ($(`#${scope.playerId}`).length) {
            // video quality
            if (scope.video) {
              videoSettings.sources = [];

              if (scope.video.lowQualityUrl) {
                hasOtherQualityUrls = true;
                videoSettings.sources.push({
                  file: scope.video.lowQualityUrl,
                  default: !!scope.video.lowDefaultPlayback,
                  label: 'low',
                });
              }
              if (scope.video.mediumQualityUrl) {
                hasOtherQualityUrls = true;
                videoSettings.sources.push({
                  file: scope.video.mediumQualityUrl,
                  default: !!scope.video.mediumDefaultPlayback,
                  label: 'medium',
                });
              }
              if (scope.video.highQualityUrl) {
                hasOtherQualityUrls = true;
                videoSettings.sources.push({
                  file: scope.video.highQualityUrl,
                  default: !!scope.video.highDefaultPlayback,
                  label: 'high',
                });
              }
              if (!!hasOtherQualityUrls && scope.video.mediaSource === '.mp4') {
                videoSettings.file = scope.video.originalUrl;
              }

              // captions
              if (scope.video.captions?.length) {
                videoSettings.tracks = scope.video.captions.map(({ type, fileUrl, language }) => {
                  let label = '';

                  // Setting the correct caption label
                  if (type !== 'auto_generated') {
                    label = $translate.instant(language ? `LANGUAGE_LABEL.${language.toUpperCase()}` : 'LECTURE_VIDEO.CLOSED_CAPTIONS');
                  } else {
                    label = `${$translate.instant(language ? `LANGUAGE_LABEL.${language.toUpperCase()}` : 'LECTURE_VIDEO.CLOSED_CAPTIONS')} [${$translate.instant('LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.AUTO_GENERATED')}]`;
                  }

                  if (type === 'auto_generated' && scope.video.autoGenerated?.caption?.errorCode) {
                    return ({});
                  }

                  return ({
                    file: fileUrl,
                    label,
                    kind: 'captions',
                    default: false,
                  });
                });
              }

              // thumbnail image
              if (scope.video.highQualityThumbnailUrl) {
                videoSettings.image = scope.video.highQualityThumbnailUrl;
              } else if (scope.video.thumbnailUrl && scope.video.thumbnailUrl !== config.video.defaultThumbnailUrl) {
                // only take the thumbnail for previewing on video player if is not the default - that is only used as thumbnail in the list view
                videoSettings.image = scope.video.thumbnailUrl;
              }
            } else {
              videoSettings.file = scope.videoUrl;
              if (scope.thumbnailUrl && scope.thumbnailUrl !== config.video.defaultThumbnailUrl) {
                videoSettings.image = scope.thumbnailUrl;
              }
            }

            if (!scope.downloadUrl) {
              if (scope.video?.downloadUrl) {
                scope.downloadUrl = scope.video.downloadUrl;
              } else if (scope.videoUrl) {
                scope.downloadUrl = scope.videoUrl;
              } else if (scope.video.highQualityUrl) {
                scope.downloadUrl = scope.video.highQualityUrl;
              } else if (scope.video.mediumQualityUrl) {
                scope.downloadUrl = scope.video.mediumQualityUrl;
              } else if (scope.video.lowQualityUrl) {
                scope.downloadUrl = scope.video.lowQualityUrl;
              }
            }

            playerInstance = jwplayer(scope.playerId).setup(videoSettings)
              .on(JWPLAYER_EVENT_NAMES.PLAY, () => {
                VideosManager.pauseAllOtherVideos(playerInstance);
                eventCallback(LectureVideoModel.BACKEND_EVENT_NAMES.PLAY);
              }).on(JWPLAYER_EVENT_NAMES.PAUSE, () => {
                eventCallback(LectureVideoModel.BACKEND_EVENT_NAMES.PAUSE);
              })
              .on(JWPLAYER_EVENT_NAMES.COMPLETE, () => {
                eventCallback(LectureVideoModel.BACKEND_EVENT_NAMES.COMPLETE);

                scope.endCallback?.();

                // we want the video to automatically go to the beginning of the video on complete
                // both the seek(play) and pause will trigger callbacks to the server to save the second
                // playerInstance.once('play', function() {
                //   playerInstance.play(false);
                // });
                // playerInstance.seek(0);
              })
              .on('time', (response) => {
                // response.duration = total video time
                // response.position = user position in video
                if (scope.timeCallback) {
                  scope.timeCallback({ second: response.position });
                }
              })
              .once('ready', () => {
                scope.readyCallback?.();
              });

            VideosManager.addVideoPlayer(playerInstance);

            playerInstance.once('play', () => {
              scope.hasPlayed = true;
            });

            if (scope.startOffset) {
              playerInstance.once('play', () => {
                playerInstance.seek(scope.startOffset);
              });
            }

            if (!_.isEmpty(scope.cuePoints)) {
              playerInstance.on('ready', () => {
                _.each(_.uniq(scope.cuePoints), (cuePoint) => {
                  element.find('.jw-slider-time .jw-slider-container').append(`<div class="jw-cue jw-reset" style="left: ${cuePoint}%;"></div>`);
                });
              });
            }

            if (scope.registerSeekToFunction) {
              scope.registerSeekToFunction({
                func(second) {
                  playerInstance.seek(second);
                },
              });
            }

            if (scope.registerPlayFunction) {
              scope.registerPlayFunction({
                func(isPlay) {
                  VideosManager.pauseAllOtherVideos(playerInstance);
                  if (isPlay) {
                    playerInstance.play();
                  } else {
                    playerInstance.pause();
                  }
                },
              });
            }
          }
        });
      }

      function eventCallback(event) {
        let totalLength;
        if (scope.eventCallback && playerInstance?.getPosition) {
          totalLength = playerInstance.getDuration();
          if (!totalLength) {
            totalLength = null;
          }

          scope.eventCallback({
            event,
            second: playerInstance.getPosition(),
            totalLength,
          });
        }
      }

      window.onbeforeunload = function () {
        eventCallback(LectureVideoModel.BACKEND_EVENT_NAMES.UNLOAD);
      };

      scope.$watch('video', (newVideo, oldVideo) => {
        if (newVideo !== oldVideo) {
          if (playerInstance) {
            playerInstance.remove();
            VideosManager.removeVideoPlayer(playerInstance);
            playerInstance = null;
          }
          setup();
        }
      });

      scope.$watch('videoUrl', (newVideoUrl, oldVideoUrl) => {
        if (newVideoUrl !== oldVideoUrl) {
          if (playerInstance) {
            playerInstance.remove();
            VideosManager.removeVideoPlayer(playerInstance);
            playerInstance = null;
          }
          setup();
        }
      });

      scope.$watch('aspectRatio', (newAspectRatio, oldAspectRatio) => {
        if (newAspectRatio !== oldAspectRatio) {
          if (playerInstance) {
            playerInstance.remove();
            VideosManager.removeVideoPlayer(playerInstance);
            playerInstance = null;
          }
          setup();
        }
      });

      setup();

      scope.$on('$destroy', () => {
        eventCallback(LectureVideoModel.BACKEND_EVENT_NAMES.UNLOAD);
        if (playerInstance) {
          playerInstance.remove(); // not sure if this is needed, but putting it in for good measure
          VideosManager.removeVideoPlayer(playerInstance);
        }
      });
    },
  };
}
