import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { isEmpty } from 'underscore';
import t from 'react-translate';
import { NovoEdFile } from 'shared/hooks/use-upload-file';
import { JWPLAYER_EVENT_NAMES } from 'shared/directives/nv-jwplayer';
import { JwPlayerVideo } from 'redux/schemas/models/video';
import { Provider, useSelector } from 'react-redux';
import store, { useAppDispatch } from 'redux/store';
import { setCurrentPlayingVideoId } from 'redux/actions/video';
import NvIcon from './nv-icon';
import { config } from '../../../config/config.json';
import NvSeekbarBadges, { Badge } from './nv-seekbar-badges';

export const unmountReact = (unmountingElementSelector: string) => {
  unmountComponentAtNode(document.querySelector(unmountingElementSelector));
};

const getRandomPositiveInteger = () => Math.floor((Math.random() * 999999999) + 1);

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum BACKEND_EVENT_NAMES {
  READY = 'ready',
  START = 'start',
  PLAY = 'play',
  PAUSE = 'pause',
  COMPLETE = 'ended',
  UNLOAD = 'unload',
}

export enum JwPlayerStates {
  BUFFERING = 'buffering',
  PLAYING = 'playing',
  IDLE = 'idle',
  PAUSED = 'paused',
  COMPLETED = 'complete',
}

export type Caption = {
  id: number;
  type?: string;
  fileUrl: string;
  fileName: string;
  language: string;
};

type NvJwPlayerProps = {
  file?: NovoEdFile;
  video?: JwPlayerVideo;
  videoUrl?: string;
  fileName?: string;
  downloadUrl?: string;
  downloadable?: boolean;
  hasPlayed?: boolean;
  autoStart?: boolean;
  startOffset?: number;
  multiSpeed?: boolean;
  playerWidth?: number;
  playerHeight?: number;
  timeCallback?: (e?: any) => void;
  eventCallback?: (e?: any) => void;
  endCallback?: (e?: string) => void;
  readyCallback?: (e?: any) => void;
  badges?: Badge[];
  onClickOnBadge?: Function;
  badgePendoTag?: string;
  registerSeekToFunction?: Function;
  registerPlayFunction?: Function;
  thumbnailUrl?: string;
  aspectRatio?: string;
  isAudio?: boolean;
  captions?: Caption[];
};

export interface NvJwPlayerImperativeInterface {
  seek: (seconds: number) => void,
  play: (isPlay: boolean) => void,
  getState: () => string,
  getPosition: () => number,
  getDuration?: () => number,
  hasPlayed: boolean,
}

const NvJwPlayer = forwardRef<NvJwPlayerImperativeInterface, NvJwPlayerProps>(({
  file,
  video,
  videoUrl,
  fileName,
  downloadable,
  downloadUrl,
  autoStart,
  multiSpeed = true,
  startOffset,
  playerWidth,
  playerHeight,
  timeCallback,
  eventCallback,
  endCallback,
  readyCallback,
  badges,
  onClickOnBadge,
  badgePendoTag,
  registerSeekToFunction,
  registerPlayFunction,
  thumbnailUrl,
  aspectRatio,
  isAudio,
  captions = [],
}, ref) => {
  const playerInstanceRef = useRef(null);

  const [downloadLink, setDownloadLink] = useState('');
  const playerIdRef = useRef(`nvjwplayer-${getRandomPositiveInteger().toString()}`);
  const playerId = playerIdRef.current;

  const [hasPlayed, setHasPlayed] = useState(false);

  const dispatch = useAppDispatch();
  const currentPlayingVideoPlayerId = useSelector((state) => state.app.currentPlayingVideoPlayerId);

  // Play or pause according to the currentPlayingVideoPlayerId
  useEffect(() => {
    if (currentPlayingVideoPlayerId === playerId) {
      // If the current video is not playing, play it
      const playerState = jwplayer(playerId).getState();
      if (![JwPlayerStates.BUFFERING, JwPlayerStates.PLAYING].includes(playerState as JwPlayerStates)) {
        jwplayer(playerId).play();
      }
    } else {
      // If the current playing player is not this player, pause this one.
      jwplayer(playerId).pause();
    }
  }, [currentPlayingVideoPlayerId, playerId]);

  useImperativeHandle(ref, () => ({
    seek: (seconds) => playerInstanceRef.current.seek(seconds),
    play: (isPlay) => (isPlay ? playerInstanceRef.current.play() : playerInstanceRef.current.pause()),
    getState: () => playerInstanceRef.current.getState(),
    getPosition: () => playerInstanceRef.current.getPosition(),
    getDuration: () => playerInstanceRef.current.getDuration(),
    hasPlayed,
  }));

  // THIS IS A CUSTOM INJECT INSIDE JWPLAYER. SO CONSIDER THIS WHEN UPGRADING
  // JWPLAYER. THIS IS USED IN PRACTICE ROOM NOW.
  // If we got badges, render it
  useEffect(() => {
    if (!isEmpty(badges) && playerInstanceRef.current) {
      const controllerContainer = document.querySelector(`#${playerId} .jw-controlbar`);

      if (controllerContainer) {
        // Remove all cue points
        controllerContainer.querySelectorAll('.nv-seekbar-badge-wrapper').forEach(e => e.remove());

        const badgeWrapper = document.createElement('div');
        badgeWrapper.className = 'nv-seekbar-badge-wrapper';

        controllerContainer.prepend(badgeWrapper);

        // Render the custom cue points to the selector
        // Redux is needed here to render custom components,
        // like NvAvatar
        render(
          <Provider store={store}>
            <NvSeekbarBadges
              playerId={playerId}
              badges={badges}
              onClickOnBadge={onClickOnBadge}
              badgePendoTag={badgePendoTag}
            />
          </Provider>,
          controllerContainer.querySelector('.nv-seekbar-badge-wrapper'),
        );
      }
    }

    return () => {
      if (document.querySelector(`#${playerId} .jw-controlbar .nv-seekbar-badge-wrapper`)) {
        unmountReact(`#${playerId} .jw-controlbar .nv-seekbar-badge-wrapper`);
      }
    };
  }, [playerId, playerInstanceRef, badges, onClickOnBadge]);

  useEffect(() => {
    let hasOtherQualityUrls = false;

    const videoSettings: Record<string, any> = {
      autostart: autoStart,
      flashplayer: video?.videoPlayerUrl,
      playbackRateControls: multiSpeed,
    };

    const jwplayerPath = '/libs/jwplayer8115/';
    if (process.env.NODE_ENV === 'production') {
      videoSettings.base = `${config.assets.host}${jwplayerPath}`;
    } else {
      videoSettings.base = `/origami${jwplayerPath}`;
    }

    // used for testing purposes on localhost
    // videoSettings.base = 'https://d2d6mu5qcvgbk5.cloudfront.net/assets/origamiprod/libs/jwplayer8115/';

    videoSettings.width = '100%';
    if (isAudio) {
      videoSettings.height = 40;
      videoSettings.aspectratio = ''; // this is required for audio
    } else {
      videoSettings.aspectratio = aspectRatio || '16:9';
    }

    if (document.getElementById(playerId)) {
      if (video) {
        videoSettings.sources = [];

        if (video.lowQualityUrl) {
          hasOtherQualityUrls = true;
          videoSettings.sources.push({
            file: video.lowQualityUrl,
            default: !!video.lowDefaultPlayback,
            label: 'low',
          });
        }
        if (video.mediumQualityUrl) {
          hasOtherQualityUrls = true;
          videoSettings.sources.push({
            file: video.mediumQualityUrl,
            default: !!video.mediumDefaultPlayback,
            label: 'medium',
          });
        }
        if (video.highQualityUrl) {
          hasOtherQualityUrls = true;
          videoSettings.sources.push({
            file: video.highQualityUrl,
            default: !!video.highDefaultPlayback,
            label: 'high',
          });
        }
        if (!!hasOtherQualityUrls && video.mediaSource === '.mp4') {
          videoSettings.file = video.originalUrl;
        }

        // captions
        if (video.transcriptUrl) {
          videoSettings.tracks = [{
            file: video.transcriptUrl,
            label: t.LECTURE_VIDEO.CLOSED_CAPTIONS(),
            kind: 'captions',
            default: true,
          }];
        }

        // thumbnail image
        if (video.highQualityThumbnailUrl) {
          videoSettings.image = video.highQualityThumbnailUrl;
        } else if (video.thumbnailUrl && video.thumbnailUrl !== config.video.defaultThumbnailUrl) {
          // only take the thumbnail for previewing on video player if is not the default - that is only used as thumbnail in the list view
          videoSettings.image = video.thumbnailUrl;
        }
      } else {
        videoSettings.file = videoUrl;
        if (thumbnailUrl && thumbnailUrl !== config.video.defaultThumbnailUrl) {
          videoSettings.image = thumbnailUrl;
        }
        // captions
        if (captions?.length > 0) {
          videoSettings.tracks = captions.map(({ type, fileUrl, language }) => {
            let label = '';

            // Setting the correct caption label
            if (type !== 'auto_generated') {
              label = language ? t.LANGUAGE_LABEL[language.toUpperCase()]() : t.LECTURE_VIDEO.CLOSED_CAPTIONS();
            } else {
              label = `${language ? t.LANGUAGE_LABEL[language.toUpperCase()]() : t.LECTURE_VIDEO.CLOSED_CAPTIONS()} ${t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.AUTO_GENERATED()}`;
            }

            return ({
              file: fileUrl,
              label,
              kind: 'captions',
              default: false,
            });
          });
        }
      }

      if (!downloadUrl) {
        if (video?.downloadUrl) {
          setDownloadLink(video.downloadUrl);
        } else if (videoUrl) {
          setDownloadLink(videoUrl);
        } else if (video.highQualityUrl) {
          setDownloadLink(video.highQualityUrl);
        } else if (video.mediumQualityUrl) {
          setDownloadLink(video.mediumQualityUrl);
        } else if (video.lowQualityUrl) {
          setDownloadLink(video.lowQualityUrl);
        }
      }

      playerInstanceRef.current = jwplayer(playerId).setup(videoSettings);

      playerInstanceRef.current.on(JWPLAYER_EVENT_NAMES.READY, () => {
        eventCallback?.(BACKEND_EVENT_NAMES.READY);
      });

      playerInstanceRef.current.on(JWPLAYER_EVENT_NAMES.PLAY, () => {
        dispatch(setCurrentPlayingVideoId(playerId));

        eventCallback?.(BACKEND_EVENT_NAMES.PLAY);

        // On play event, focus on the player
        playerInstanceRef.current.getContainer().focus();

        // This is purposefully executed after eventCallback. So on first run
        // we could identify whether this played before
        setHasPlayed(true);
      });

      playerInstanceRef.current.on(JWPLAYER_EVENT_NAMES.PAUSE, () => {
        eventCallback?.(BACKEND_EVENT_NAMES.PAUSE);
      });

      playerInstanceRef.current.on(JWPLAYER_EVENT_NAMES.COMPLETE, () => {
        eventCallback?.(BACKEND_EVENT_NAMES.COMPLETE);
        endCallback?.();
      });

      window.onbeforeunload = () => {
        eventCallback?.(BACKEND_EVENT_NAMES.UNLOAD);
      };
    }
  }, [
    autoStart,
    multiSpeed,
    registerSeekToFunction,
    video,
    aspectRatio,
    endCallback,
    eventCallback,
    isAudio,
    thumbnailUrl,
    videoUrl,
    playerId,
    downloadUrl,
    dispatch,
  ]);

  return (
    <div className='clearfix'>
      {downloadable && (
        <div className='jwplayer-download'>
          { /* eslint-disable-next-line react/jsx-no-target-blank */ }
          <a className='download-link' href={downloadLink} target='_blank'>
            <NvIcon icon='download' size='smallest' />
          </a>
          {fileName && <div className='d-inline-block'>{fileName}</div>}
        </div>
      )}
      <div className='jwplayer-container'>
        <div id={playerId}>
          {!hasPlayed && (
            <div className='preview-generation'>{t.VIDEO.GENERATING_PREVIEW_ONLY()}</div>
          )}
        </div>
      </div>
    </div>
  );
});

export default NvJwPlayer;
