import humps from 'humps';
import store from 'redux/store';
import { updateAutoGeneratedTranscript, updateAutoGeneratedCaption } from 'redux/actions/lecture-components';
import t from '../../../react-translate';

const TRANSCRIPT_CHANNEL = 'auto-generate-transcription';
const CAPTION_CHANNEL = 'auto-generate-caption';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
    onContentReady: '<',
  },
  controller: function ctrl(
    $controller,
    $rootScope,
    $scope,
    $timeout,
    CurrentCourseManager,
    InteroperabilityRoutes,
    LectureComponentsHelper,
    config,
    PusherManager,
    PusherChannels,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;
    vm.InteroperabilityRoutes = InteroperabilityRoutes;
    vm.CurrentCourseManager = CurrentCourseManager;

    initialize();

    function initialize() {
      if (vm.lectureComponent.currentLectureVideo) {
        vm.lectureComponent.currentLectureVideo.readyCallback = () => {
          vm.onContentReady();
          setupPusherEvents(vm.lectureComponent.currentLectureVideo.video);
        };
      }
    }

    /* Admin Actions */
    vm.editBasics = () => {
      const modalInstance = LectureComponentsHelper.showEditModal(vm.lectureComponent, {
        windowClass: 'large-modal video-modal',
      });
    };

    vm.reorderVideos = () => {
      vm.lectureComponent.reorderMode = true;
      vm.lectureComponent.clickedReorder = true;
    };

    vm.saveTitleOnBlur = () => {
      vm.lectureComponent.defaultTitle = false;
      vm.lectureComponent.save();
    };

    vm.setEditMenu = () => {
      const { sharedProps } = this.context;
      const extraOptions = [];

      extraOptions.push({
        type: 'header',
        title: t.LECTURE_VIDEO.VIDEO_LIST(),
      });

      if (vm.lectureComponent.lectureVideos.length > 1) {
        extraOptions.push(sharedProps.extraOptions.getEditOption(t.LECTURE_VIDEO.ADD_VIDEO_TO_PLAYLIST()));

        extraOptions.push({
          type: 'text',
          text: t.LECTURE_AUDIO.REORDER_PLAYLIST(),
          callback: () => vm.reorderVideos(),
        });
      } else if (vm.lectureComponent.lectureVideos.length === 1) {
        extraOptions.push(sharedProps.extraOptions.getEditOption(t.LECTURE_VIDEO.ADD_VIDEO_TO_CREATE_PLAYLIST()));

        extraOptions.push({
          type: 'text',
          text: t.LECTURE_VIDEO.EDIT_VIDEO(),
          callback: () => vm.lectureComponent.editIndividualVideo(vm.lectureComponent.lectureVideos[0]).then(() => {
            $timeout(() => {}, 0);
          }),
          dataQa: config.pendo.editVideo.editVideo,
        }, {
          type: 'divider',
        });

        if (!vm.lectureComponent.lectureVideos[0].topicId) {
          extraOptions.push({
            type: 'text',
            text: t.LECTURE_VIDEO.ADD_DISCUSSION(),
            callback: () => vm.lectureComponent.addDiscussion(vm.lectureComponent.lectureVideos[0], () => vm.setEditMenu()),
            disabled: vm.CurrentCourseManager.course.isContentManagementCollection,
          });
        } else {
          extraOptions.push({
            type: 'text',
            text: t.LECTURE_VIDEO.DISCUSSIONS.EDIT_DISCUSSION(),
            callback: () => vm.lectureComponent.addDiscussion(vm.lectureComponent.lectureVideos[0], () => vm.setEditMenu()),
            disabled: vm.CurrentCourseManager.course.isContentManagementCollection,
          });
        }

        if (!vm.lectureComponent.lectureVideos[0].questionSetId) {
          extraOptions.push({
            type: 'link',
            text: t.LECTURE_VIDEO.ADD_QUIZ(),
            link: vm.InteroperabilityRoutes.videoQuizEditPath(vm.CurrentUserManager.coursesHashByCatalogId[vm.lectureComponent.catalogId], vm.lectureComponent.lectureVideos[0].id),
            disabled: vm.CurrentCourseManager.course.isContentManagementCollection,
          });
        } else {
          extraOptions.push({
            type: 'link',
            text: t.LECTURE_VIDEO.EDIT_QUIZ(),
            link: vm.InteroperabilityRoutes.editQuizPath(vm.CurrentUserManager.coursesHashByCatalogId[vm.lectureComponent.catalogId], vm.lectureComponent.lectureVideos[0].questionSetId),
            disabled: vm.CurrentCourseManager.course.isContentManagementCollection,
          });
        }
      }

      sharedProps.extraOptions = {
        ...sharedProps.extraOptions,
        ...{
          options: extraOptions,
        },
      };

      if (vm.lectureComponent.lectureVideos.length > 1) {
        sharedProps.deleteText = t.LECTURE_PAGES.COMPONENTS.VIDEO_LIST.DELETE();
      } else {
        sharedProps.deleteText = t.LECTURE_PAGES.COMPONENTS.DELETE();
      }
    };

    vm.setEditMenu();

    function setupPusherEvents(video) {
      const videoHasAutoGenerate = video.autoGenerateTranscript || video.autoGenerateCaption;

      if (videoHasAutoGenerate) {
        const coursePusherChannel = PusherManager.setupChannel(PusherChannels.courseChannel(CurrentCourseManager.course.id));
        const transcriptChannelExists = coursePusherChannel.baseChannel.callbacks.get(TRANSCRIPT_CHANNEL);
        const captionChannelExists = coursePusherChannel.baseChannel.callbacks.get(CAPTION_CHANNEL);

        if (!transcriptChannelExists) {
          coursePusherChannel.bind(TRANSCRIPT_CHANNEL, (data) => {
            const pusherData = humps.camelizeKeys(data);
            // Updating the redux store
            store.dispatch(updateAutoGeneratedTranscript({
              videoId: pusherData.videoId,
              params: pusherData,
            }));
            // Updating the angular state
            video.autoGenerated.transcript = pusherData;
            // Broadcasting the pusher data to the video edit modal
            $rootScope.$broadcast('video-transcript-updated', pusherData);
          });
        }

        if (!captionChannelExists) {
          coursePusherChannel.bind(CAPTION_CHANNEL, (data) => {
            const pusherData = humps.camelizeKeys(data);
            // Updating the redux store
            store.dispatch(updateAutoGeneratedCaption({
              videoId: pusherData.videoId,
              params: pusherData,
            }));
            // Updating the angular state
            video.autoGenerated.caption = pusherData;
            // Broadcasting the pusher data to the video edit modal
            $rootScope.$broadcast('video-caption-updated', pusherData);
          });
        }
      }
    }

    $scope.$watch('vm.lectureComponent.lectureVideos.length', () => {
      vm.setEditMenu();
    });

    $scope.$watch('vm.lectureComponent.lectureVideos[0].topicId', () => {
      vm.setEditMenu();
    });

    $scope.$watch('vm.lectureComponent.lectureVideos[0].questionSetId', () => {
      vm.setEditMenu();
    });
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-video-list-lecture-component.html',
};
