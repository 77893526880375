import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { isEmpty, map } from 'underscore';

import { useSelector } from 'react-redux';
import { getCurrentLecture, getCurrentLectureSection } from 'redux/selectors/lecture-page';
import { NovoAIContentType } from 'redux/schemas/models/lecture-page';

import { standardSpacing } from 'styles/global_defaults/scaffolding';

import Content from './content';

export type AIContent = {
  id: number;
  title: string
  description: string
  type: NovoAIContentType
};

const styles = css`
  & {
    gap: ${standardSpacing}px;
  }
`;

const List = () => {
  const currentLecture = useSelector(getCurrentLecture);
  const currentSubSection = useSelector(state => getCurrentLectureSection(state, 'LectureSubsection'));
  const currentSection = useSelector(state => getCurrentLectureSection(state, 'LectureSection'));
  const selectedItemType = useSelector(state => state.app.lecturePage.novoAI.itemType);
  const selectedContent = useSelector(state => state.app.lecturePage.novoAI.content);

  const listRef = useRef<HTMLDivElement>(null);

  const contents: AIContent[] = [];

  if (!isEmpty(currentLecture)) {
    contents.push({
      id: currentLecture?.id,
      title: t.LECTURE_PAGES.LHS.NOVO_AI.CONTENT.LESSON(),
      description: currentLecture.title,
      type: NovoAIContentType.LESSON,
    });
  }

  if (!isEmpty(currentSubSection)) {
    contents.push({
      id: currentSubSection.id,
      title: t.LECTURE_PAGES.LHS.NOVO_AI.CONTENT.SUB_SECTION(),
      description: currentSubSection.title,
      type: NovoAIContentType.SUBSECTION,
    });
  }

  if (!isEmpty(currentSection)) {
    contents.push({
      id: currentSection.id,
      title: t.LECTURE_PAGES.LHS.NOVO_AI.CONTENT.SECTION(),
      description: currentSection.title,
      type: NovoAIContentType.SECTION,
    });
  }

  useEffect(() => {
    if (listRef.current && !isEmpty(selectedItemType) && isEmpty(selectedContent)) {
      setTimeout(() => {
        listRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  }, [selectedContent, selectedItemType]);

  return (
    <div className='d-flex flex-column' css={styles} ref={listRef}>
      <div className='page-title text-center'>
        {t.LECTURE_PAGES.LHS.NOVO_AI.CONTENT_HEADER()}
      </div>
      {map(contents, (content, key) => (
        <Content content={content} key={content.id} />
      ))}
    </div>
  );
};

export default List;
