import React, { useContext, useEffect } from 'react';
import t from 'react-translate';
import { AngularContext } from 'react-app';

// Form
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


// Types
import {
  LiveEvent,
  LiveEventSession,
  SessionTypes,
  getDefaultLiveEvent,
  SessionSettings,
  getLiveEventPayload,
  Draft,
} from 'redux/schemas/models/live-event';
import {
  getSessionSchema,
  getSessionSettingsSchema,
} from 'redux/schemas/models/live-event-schema';

// Components
import { Button } from 'react-bootstrap';
import _ from 'underscore';
import { config } from '../../../config/pendo.config.json';
import LiveEventSessions from './live-event-sessions';
import LiveEventSettings from './live-event-settings';
// import MultiSessionSettings from './multi-session-settings';

export type AngularLiveEvent = {
  currentTimeZone: string
  id?: number
  sessions: LiveEventSession[]
  mainTitle: string
  mainDescription?: string
};

type LiveSessionFormProps = {
  draft: AngularLiveEvent
  sessionType: SessionTypes
  lectureComponent: any // TODO: Set the correct type
  msTeamsEmail: string
  creatorUserCourseId: number
  durations: number[]
  isLinked: boolean
  isContentManagementCollection: boolean
  isAccountLevel: boolean
  save: () => void
  cancel: () => void
  validateHost: (host: string) => boolean
  showZoomHostValidator: () => boolean
  deleteSession: (sessionId: number, onSuccess? : (value: unknown) => void) => void
};

const LiveSessionForm = ({
  draft,
  sessionType = SessionTypes.EXTERNAL,
  lectureComponent,
  msTeamsEmail,
  creatorUserCourseId,
  durations,
  isLinked,
  isContentManagementCollection,
  isAccountLevel,
  save,
  cancel,
  validateHost,
  showZoomHostValidator,
  deleteSession,
}: LiveSessionFormProps) => {
  const { injectServices } = useContext(AngularContext);
  const [CurrentUserManager] = injectServices(['CurrentUserManager']);
  const timezone = CurrentUserManager.user.timeZone ?? CurrentUserManager.user.browserTimeZone;
  const [isValidHost, setIsValidHost] = React.useState(false);
  const [sessionsDeleted, setSessionsDeleted] = React.useState<number[]>([]);

  const sessionSchema: yup.Schema<LiveEventSession> = getSessionSchema({
    sessionType,
    isContentManagementCollection,
    errorMessages: {
      required: t.VALIDATION.REQUIRED(),
      url: t.VALIDATION.URL(),
      email: t.VALIDATION.EMAIL(),
    },
    isAccountLevel,
  });
  const settingsSchema: yup.Schema<SessionSettings> = getSessionSettingsSchema(sessionType);

  const validationSchema: yup.Schema<LiveEvent> = yup.object().shape({
    sessionType: yup.string().oneOf([SessionTypes.EXTERNAL, SessionTypes.MS_TEAMS, SessionTypes.ZOOM]).required(),
    multisessionSetting: yup.boolean().required(),
    sessions: yup.array().of(sessionSchema),
    settings: settingsSchema,
  });

  const defaultLiveEvent: LiveEvent = getDefaultLiveEvent(
    sessionType,
    ({ ...draft, timezone } as Draft),
    isContentManagementCollection,
    isLinked,
    isAccountLevel,
  );

  const methods = useForm<LiveEvent>({
    mode: 'all',
    defaultValues: defaultLiveEvent,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, formState } = methods;

  const submit = (data: any) => {
    lectureComponent.getPayload = () => getLiveEventPayload(
      data,
      creatorUserCourseId,
      sessionType,
      isAccountLevel,
      draft,
      isContentManagementCollection,
    );
    if (sessionsDeleted.length === 0) {
      save();
    } else {
      const promises = sessionsDeleted.map((sessionId) => new Promise((resolve) => deleteSession(sessionId, resolve)));
      Promise.all(promises).then(save);
    }
  };

  const shouldDisable = () => {
    if (!formState.isValid) {
      if (sessionsDeleted.length) {
        return !_.isEmpty(formState.dirtyFields);
      } return true;
    }
    return false;
  };

  const isSubmitBtnDisabled = () => (
    formState.isSubmitting
    || !formState.isDirty
    || (shouldDisable())
    || (isAccountLevel && !isValidHost && !isContentManagementCollection)
  );

  const onHostValidation = (isValid: boolean) => {
    setIsValidHost(isValid);
  };
  const handleSessionDeleted = (id: number) => {
    setSessionsDeleted([...sessionsDeleted, id]);
  };

  useEffect(() => () => {
    methods.reset();
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        {/* Live event sessions */}
        <LiveEventSessions
          sessionType={sessionType}
          getDurationText={lectureComponent.durationText}
          durations={durations}
          validateHost={validateHost}
          showZoomHostValidator={showZoomHostValidator}
          onHostValidation={onHostValidation}
          isContentManagementCollection={isContentManagementCollection}
          isLinked={isLinked}
          onSessionDeleted={handleSessionDeleted}
        />

        {/* Live event settings */}
        {sessionType !== SessionTypes.EXTERNAL && (
          <LiveEventSettings
            sessionType={sessionType}
            msTeamsEmail={msTeamsEmail}
            disabled={isContentManagementCollection && !!draft.id}
          />
        )}

        {/* Multisession settings */}
        {/* Uncomment when learner registration is enabled */}
        {/* {sessions.length > 1 && <MultiSessionSettings />} */}

        {/* Buttons bar */}
        <div className='mt-4 text-align-center'>
          <Button
            type='button'
            variant='secondary'
            onClick={cancel}
            className='mr-2'
            data-qa={config.pendo.liveEvent.liveEventSession.cancel}
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            type='submit'
            disabled={isSubmitBtnDisabled()}
            data-qa={config.pendo.liveEvent.liveEventSession.save}
          >
            {draft.id ? t.FORM.UPDATE() : t.FORM.ADD()}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default LiveSessionForm;
