import React, { useRef } from 'react';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import t from 'react-translate';

// Redux
import { NQuizQuestion, QuizSubmission } from 'redux/schemas/models/progressive-quiz';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { wrapThunkAction } from 'redux/utils';
import { createQuizQuestionSubmission, submitQuizAttempt } from 'redux/actions/quizzes';

import { useTimelineService } from 'timelines/services/react-timeline-service';
import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import useForceUpdate from 'shared/hooks/use-force-update';
import { css } from '@emotion/core';
import { gray5 } from 'styles/global_defaults/colors';
import { doubleSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../config/pendo.config.json';
import { ProgressiveQuizMode } from './mode';
import QuizQuestionContent from './quiz-question-content';
import QuizImage from './quiz-image';

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion;
  className?: string;
};
const StatementQuizQuestion = (props: QuizQuestionProps) => {
  const { className, currentQuestion } = props;

  const dispatch = useAppDispatch();
  const [buttonOffsetWidth, setButtonOffsetWidth] = React.useState(0);

  const { injectServices } = React.useContext(AngularContext);

  const [
    $uibModal,
    TimelinesManager,
  ] = injectServices([
    '$uibModal',
    'TimelinesManager',
  ]);

  const currentLectureId = useSelector(state => state.app.lecturePage?.currentLectureId);
  const timelineService = useTimelineService();
  const forceUpdate = useForceUpdate();

  const {
    mode,
    scrollRef,
    closeModal,
    fetchQuestion,
    progressiveQuiz,
    lectureComponent,
    currentQuestionIndex,
    currentQuestionResponse,
    isSubmitting,
    setIsSubmitting,
  } = React.useContext(ProgressiveQuizContext);

  // This state update is to trigger the recalculation of the CTA button position when image is loaded
  const handleImageLoad = () => {
    setTimeout(() => {
      forceUpdate();
    }, 0);
  };

  const areQuestionsLeft = (currentQuestionIndex + 1) < progressiveQuiz.totalQuestions;

  const isEditMode = mode === ProgressiveQuizMode.EDIT;
  const isAnswerMode = mode === ProgressiveQuizMode.ANSWER;

  const quizQuestionRef = useRef(null);
  const buttonContainerRef = useRef(null);
  const quizQuestionRect = quizQuestionRef?.current?.getBoundingClientRect();
  const buttonContainerRect = buttonContainerRef?.current?.getBoundingClientRect();

  React.useLayoutEffect(() => {
    if (buttonContainerRef?.current) {
      const button = buttonContainerRef?.current?.querySelector('button');
      setButtonOffsetWidth(button.offsetWidth);
    }
  }, [buttonContainerRef]);

  const getPosition = () => {
    // used to indicate that the bottom margin of the button has become greater than 40px.
    const isButtonBottomMarginExceeds = (scrollRef?.current?.getBoundingClientRect()?.bottom - buttonContainerRect?.bottom) > 40;
    // used to indicate that the top margin has become less than 60px.
    const isRequiredButtonTopMarginReduced = (buttonContainerRect?.top - (quizQuestionRect?.height + quizQuestionRect?.top)) < 60;
    if (!isButtonBottomMarginExceeds && isRequiredButtonTopMarginReduced) {
      return 'static';
    }
    return 'fixed';
  };
  const styles = css`
    .bottom-section {
      ${!isEditMode && css`
        border-top: 4px solid ${gray5};
      `};
    }
    .button-container {
      position: ${getPosition()};
      left: calc(50% - ${buttonOffsetWidth / 2}px);
      margin-top: ${tripleSpacing}px;
      bottom: ${window.innerHeight - scrollRef?.current?.getBoundingClientRect()?.bottom + doubleSpacing}px;
    }
  `;

  const handleNext = () => {
    const nextQuestionIndex = currentQuestionIndex + 1;
    setIsSubmitting(true);
    if (isAnswerMode && !currentQuestionResponse) {
      wrapThunkAction(dispatch(createQuizQuestionSubmission({
        questionId: currentQuestion!.id,
        userResponse: '',
      }))).then(() => {
        fetchQuestion({
          page: nextQuestionIndex + 1,
        }).then(() => {
          setIsSubmitting(false);
        });
      }).catch(() => {
        setIsSubmitting(false);
      });
      return;
    }
    fetchQuestion({
      page: nextQuestionIndex + 1,
    }).then(() => {
      setIsSubmitting(false);
    });
  };

  const handleFinish = () => {
    setIsSubmitting(true);
    wrapThunkAction(dispatch(createQuizQuestionSubmission({
      questionId: currentQuestion!.id,
      userResponse: '',
    }))).then(() => {
      wrapThunkAction(dispatch(submitQuizAttempt(progressiveQuiz.id))).then(({ payload }) => {
        const {
          pointsReceived,
          leaderboardRank,
          leaderboardPoints,
          priorLeaderboardRank,
          quiz: { progress },
        } = (payload as QuizSubmission);

        closeModal(true);

        timelineService.updateTimeline(currentLectureId);
        if (pointsReceived) {
          TimelinesManager.updateComponentPointsAndProgress(
            currentLectureId,
            lectureComponent.type,
            lectureComponent.id,
            pointsReceived,
            null,
            progress,
          );

          $uibModal.open({
            templateUrl: 'shared/templates/points-modal.html',
            windowClass: 'points-modal',
            controller: 'PointsModalCtrl as vm',
            resolve: {
              extras: null,
              pointsReceived,
              leaderboardRank,
              leaderboardPoints,
              priorLeaderboardRank,
            },
          });
        } else {
          TimelinesManager.updateComponentProgress(
            currentLectureId,
            lectureComponent.type,
            lectureComponent.id,
            progress,
          );
        }
      }).finally(() => {
        setIsSubmitting(false);
      });
    }).catch(() => {
      setIsSubmitting(false);
    });
  };

  const renderNextButton = () => (
    <Button
      size='sm'
      disabled={isSubmitting}
      onClick={handleNext}
      data-qa={config.pendo.activities.progressiveQuiz.questionModal.next}
    >
      {t.SHARED.NEXT()}
    </Button>
  );

  const renderNextAction = () => (
    <>
      {(!areQuestionsLeft) ? (
        <Button
          size='sm'
          onClick={handleFinish}
          disabled={isSubmitting}
          data-qa={config.pendo.activities.progressiveQuiz.questionModal.finishQuiz}
        >
          {t.QUIZZES.FINISH_QUIZ()}
        </Button>
      ) : renderNextButton()}
    </>
  );
  const questionContextValue = {
    currentQuestion,
  };

  return (
    <QuestionContext.Provider value={questionContextValue} key={currentQuestion.id}>
      <div ref={quizQuestionRef} css={styles} className={className}>
        <QuizQuestionContent className='mb-5' />
        {(isEditMode ? true : !!currentQuestion.picture?.cdnUrl) && (
        <div className='d-flex justify-content-center mb-5'>
          <QuizImage
            handleImageLoad={handleImageLoad}
            className='question-image'
          />
        </div>
        )}

        {isAnswerMode && (
        <div ref={buttonContainerRef} className='d-flex justify-content-center button-container'>
          {renderNextAction()}
        </div>
        )}
      </div>
    </QuestionContext.Provider>
  );
};

export default StatementQuizQuestion;
