import { useAngularPreventLecturePageNavigation } from '../../lecture_pages/hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function NvDiscussionNewComment(
  CurrentUserManager,
  CurrentCourseManager,
  DiscussionsManager,
  StateManager,
  _,
  ScrollFocusConnectorFactory,
  $timeout,
  nvCurrentPage,
  MentionablesModel,
  $uibPosition,
  AlertMessages,
  $uibModal,
  TimelinesManager,
  ReactTimelineService,
  nvUtil,
  ConfirmationOverlays,
  config,
  ReactLecturePageContext,
  ReportModel,
  TeamManager,
) {
  return {
    restrict: 'A',
    scope: {
      post: '=',
      report: '=',
      editable: '=',
      scrollable: '=',
      existingComment: '=?',
      placeholderText: '@?',
      context: '@',
      mentionMenuTemplateUrl: '=?',
      previewMode: '<?',
      hideVideo: '<?', // used in lecture pages because we dont' want to show the video
    },
    require: ['nvDiscussionNewComment', '?^^nvDiscussionPost', '?^^nvIndividualSubmission', '?^^nvDiscussionComment'],
    controller: function ctrl($scope, $element) {
'ngInject';
      _.extend(this, {
        CurrentUserManager,
        CurrentCourseManager,
        MentionablesModel,
        DiscussionsManager,
        TeamManager,

        showInstructionsModal,

        commentContent: this.existingComment ? this.existingComment.body : null,
        includeVideoTimestamp: this.existingComment ? this.existingComment.videoTimestamp != null : false,
        videoTimestamp: null,
        submitting: false,
        config,
        isNotOnTeam: TeamManager.isNotOnTeam,
      });

      const initConfirmationOverlay = () => {
        const msg = 'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY';
        const overlay = 'discussions/templates/nv-unsaved-changes-confirmation-overlay.html';
        const unsavedChanges = () => !!(
          this.existingComment ? this.existingComment.body !== this.commentContent : this.commentContent?.length
        );

        // Not a hook.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAngularPreventLecturePageNavigation($scope, unsavedChanges);

        this.deregisterStateChangeStart = StateManager.registerStateChangeStart(unsavedChanges, overlay, msg);
      };

      this.$onInit = async function () {
        initConfirmationOverlay();
        await TeamManager.checkUserIsNotOnTeam(this?.report?.owner?.id);
        this.isNotOnTeam = TeamManager.isNotOnTeam;
      };

      this.$onDestroy = function () {
        this.deregisterStateChangeStart?.();
      };

      // Get current Course from the state catalogId
      this.course = CurrentUserManager.getCurrentCourse();
      if (!this.course) {
        // Fallback for some reason course is not available use course set in CurrentCourseManager
        this.course = CurrentCourseManager.course;
      }

      this.adminIsEditingOtherComment = this.existingComment?.user.id !== CurrentUserManager.user.id && CurrentUserManager.isAdminForCurrentCourse();
      if (this.report?.exercise?.customQuestions) {
        this.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.report.exercise.customQuestions.pointsPerPeerReview,
          this.report.exercise.customQuestions.releaseDate, this.course.isDecayEnabled());
      }

      function showInstructionsModal() {
        $uibModal.open({
          templateUrl: 'submissions/templates/informal-feedback-modal.html',
          windowClass: 'informal-feedback-modal',
          controller: 'InformalFeedbackModalCtrl as vm',
          resolve: {
            currentExercise: this.report.exercise,
            currentPeerEvaluation: this.report.exercise.customQuestions,
          },
        });
      }

      this.hasUnsavedChanges = () => !this.previewMode && this.newCommentForm?.$dirty && this.commentContent.length && !this.submitting;

      this.editorFocus = () => {
        this.editorInFocus = true;
      };

      this.editorBlur = () => {
        this.editorInFocus = false;
      };

      this.editorPopoverShown = () => {
        this.editorPopoverIsShown = true;
      };

      this.editorPopoverHidden = () => {
        this.editorPopoverIsShown = false;
      };
    },
    link: {
      pre: (scope, elem, attrs, ctrls) => {
        const [newCommentCtrl] = ctrls;
        newCommentCtrl.scrollParent = $uibPosition.scrollParent(elem);
      },
      post: (scope, elem, attrs, ctrls) => {
        const [newCommentCtrl, postCtrl, reportCtrl, commentCtrl] = ctrls;

        newCommentCtrl.handleCommentActions = handleCommentActions;
        newCommentCtrl.submitComment = submitComment;
        newCommentCtrl.isAvailable = isAvailable;
        newCommentCtrl.reset = reset;

        registerCommentCallbacks();

        toggleScrollFocusRegistering(newCommentCtrl.scrollable);

        newCommentCtrl.canLeaveFeedack = function () {
          return newCommentCtrl.report.canLeaveFeedback();
        };

        newCommentCtrl.hideNewCommentUi = () => {
          if (commentCtrl) {
            commentCtrl.cancelUpdateComment(); // remove directive all together
          } if (postCtrl) {
            postCtrl.hideNewCommentUi();
          } if (reportCtrl) {
            reportCtrl.hideNewCommentUi();
          } else {
            newCommentCtrl.editable = !newCommentCtrl.editable;
          }

          newCommentCtrl.commentContent = '';
        };

        newCommentCtrl.toggleEditable = function () {
          if (newCommentCtrl.editable) {
            if (newCommentCtrl.hasUnsavedChanges()) {
              const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-inline-changes-confirmation-overlay.html');

              modalOverlay.result.then(newCommentCtrl.hideNewCommentUi);
            } else {
              newCommentCtrl.hideNewCommentUi();
            }
          } else {
            if (postCtrl) {
              // while in collapsed mode the lecture discussion component
              // should load the comments, when the user interact's with the comment prompt.
              if (postCtrl.context === 'lecture' && !postCtrl.commentsModeOn) {
                postCtrl.fetchComments(false, false, false)
                  .then(() => {
                    ScrollFocusConnectorFactory.scrollTo(postCtrl.post.id, 'post', { offset: 250 });
                  });
              }
              postCtrl.showNewCommentUi();
            } if (reportCtrl) {
              reportCtrl.showNewCommentUi();
            } else {
              newCommentCtrl.editable = !newCommentCtrl.editable;
            }
          }
        };

        newCommentCtrl.editComment = function (existingComment) {
          newCommentCtrl.existingComment = existingComment;
          newCommentCtrl.commentContent = existingComment.body;
          newCommentCtrl.includeVideoTimestamp = existingComment.videoTimestamp != null;
          newCommentCtrl.videoTimestamp = null;
          newCommentCtrl.editable = true;
        };

        function isAvailable() {
          let topic = newCommentCtrl.post?.owner;
          if (!topic && newCommentCtrl.post?.topicId) {
            topic = newCommentCtrl.DiscussionsManager?.findTopic(newCommentCtrl.post.topicId);
          }
          return (!topic ? true : !topic.locked);
        }

        function submitComment() {
          newCommentCtrl.submitting = true;
          if (newCommentCtrl.commentContent.length && newCommentCtrl.post) {
            if (newCommentCtrl.existingComment) {
              // we are updating
              return newCommentCtrl.post.updateComment(_.extend(newCommentCtrl.existingComment, {
                body: newCommentCtrl.commentContent,
                includeVideoTimestamp: newCommentCtrl.includeVideoTimestamp,
                videoTimestamp: newCommentCtrl.includeVideoTimestamp ? newCommentCtrl.videoTimestamp || newCommentCtrl.existingComment.videoTimestamp : null,
              })).then(reset);
            }
            const previousPointsReceived = newCommentCtrl.post.pointsReceived || 0;

            return DiscussionsManager.createComment(
              newCommentCtrl.commentContent,
              newCommentCtrl.post, false,
              newCommentCtrl.includeVideoTimestamp,
              newCommentCtrl.includeVideoTimestamp ? newCommentCtrl.videoTimestamp : null,
              MentionablesModel.parseMentionables(newCommentCtrl.commentContent),
            ).then(() => {
              newCommentCtrl.post.commentedByUser = true;
              newCommentCtrl.post.progress = 'completed';

              if (newCommentCtrl.post.pointsReceived && newCommentCtrl.post.pointsReceived > previousPointsReceived) {
                ReactTimelineService.updateTimeline(newCommentCtrl.post.metaContent.lecturePage.id);
                TimelinesManager.updateComponentPointsAndProgress(newCommentCtrl.post.metaContent.lecturePage.id, 'topic', newCommentCtrl.post.id,
                  newCommentCtrl.post.pointsReceived);

                $uibModal.open({
                  templateUrl: 'shared/templates/points-modal.html',
                  windowClass: 'points-modal',
                  controller: 'PointsModalCtrl as vm',
                  resolve: {
                    pointsReceived: newCommentCtrl.post.pointsReceived,
                    leaderboardPoints: newCommentCtrl.post.leaderboardPoints,
                    leaderboardRank: newCommentCtrl.post.leaderboardRank,
                    priorLeaderboardRank: newCommentCtrl.post.priorLeaderboardRank,
                    extras: null,
                  },
                });
              } else if (newCommentCtrl.post.metaContent?.lecturePage) {
                ReactTimelineService.updateTimeline(newCommentCtrl.post.metaContent.lecturePage.id);
                TimelinesManager.updateComponentProgress(newCommentCtrl.post.metaContent.lecturePage.id, 'topic', newCommentCtrl.post.id, 'completed');
              }

              reset();
            }, () => {
              /**
               * Giving the failure feedback to user only on promise rejection,
               * to prevent error of showing because of any thrown error.
               */
              AlertMessages.error('', 'FORM.ERROR');
              newCommentCtrl.submitting = false;
            });
          } if (newCommentCtrl.commentContent.length && newCommentCtrl.report) {
            if (newCommentCtrl.existingComment) {
              return newCommentCtrl.report.updateComment(_.extend(newCommentCtrl.existingComment, { body: newCommentCtrl.commentContent })).then(reset);
            }
            return newCommentCtrl.report.createComment(newCommentCtrl.commentContent).then(() => {
              newCommentCtrl.report.posted = true;

              if (newCommentCtrl.report.isPublicFeedbackOn) {
                if (newCommentCtrl.report.latestComment.isPublicFeedback) {
                  newCommentCtrl.report.gavePublicFeedback = true;
                }

                ReportModel.normalizeSubmissionData(newCommentCtrl.report.latestComment.owner);

                ReactLecturePageContext?.updateAngularComponentStatus?.(
                  'exercise_peer_review',
                  newCommentCtrl.report.exercise.customQuestions.id,
                  newCommentCtrl.report.latestComment,
                );
              }

              // If comment is informal feedback
              if (newCommentCtrl.report.latestComment.isPublicFeedback) {
                if (newCommentCtrl.report.latestComment.pointsReceived) {
                  ReactTimelineService.updateTimeline(newCommentCtrl.report.exercise.customQuestions.lecturePageId);
                  TimelinesManager.updateComponentPointsAndProgress(
                    newCommentCtrl.report.exercise.customQuestions.lecturePageId,
                    'exercise_peer_review',
                    newCommentCtrl.report.exercise.customQuestions.id,
                    newCommentCtrl.report.peerEvaluation.pointsReceived,
                    null,
                    'completed',
                  );

                  $uibModal.open({
                    templateUrl: 'shared/templates/points-modal.html',
                    windowClass: 'points-modal',
                    controller: 'PointsModalCtrl as vm',
                    resolve: {
                      pointsReceived: newCommentCtrl.report.latestComment.pointsReceived,
                      leaderboardPoints: newCommentCtrl.report.latestComment.leaderboardPoints,
                      leaderboardRank: newCommentCtrl.report.latestComment.leaderboardRank,
                      priorLeaderboardRank: newCommentCtrl.report.latestComment.priorLeaderboardRank,
                      extras: null,
                    },
                  });
                } else {
                  if (newCommentCtrl.report.latestComment.peerEvaluation.numEvaluated === newCommentCtrl.report.exercise.customQuestions.targetGoal) {
                    AlertMessages.success('', 'INFORMAL_FEEDBACK.ALL_SUBMISSIONS_SUCCESS', {}, { targetGoal: newCommentCtrl.report.exercise.customQuestions.targetGoal });
                  } else {
                    AlertMessages.success('', 'INFORMAL_FEEDBACK.SUBMISSION_SUCCESS');
                  }
                  ReactTimelineService.updateTimeline(newCommentCtrl.report.exercise.customQuestions.lecturePageId);
                  TimelinesManager.updateComponentProgress(newCommentCtrl.report.exercise.customQuestions.lecturePageId, 'exercise_peer_review',
                    newCommentCtrl.report.exercise.customQuestions.id, 'completed');
                }
              }
              reset();
            }, () => {
              /**
               * Giving the failure feedback to user only on promise rejection,
               * to prevent error of showing because of any thrown error.
               */
              AlertMessages.error('', 'FORM.ERROR');
              newCommentCtrl.submitting = false;
            });
          }

          return null;
        }

        function reset() {
          newCommentCtrl.newCommentForm.$setPristine();

          newCommentCtrl.submitting = false;

          // clear out old comment value
          newCommentCtrl.commentContent = null;

          ScrollFocusConnectorFactory.deregister(
            newCommentCtrl.post ? newCommentCtrl.post.id : newCommentCtrl.report.id,
            newCommentCtrl.post ? 'post' : 'report',
          );

          newCommentCtrl.editable = false;
          newCommentCtrl.existingComment = null;

          newCommentCtrl.includeVideoTimestamp = false;
          newCommentCtrl.videoTimestamp = null;

          if (commentCtrl) {
            commentCtrl.afterCommentSubmit();
          }

          // if the post was in collapsed state, after create should be in expanded state
          if (postCtrl) {
            postCtrl.fetchComments(false, false, false);
          }

          if (reportCtrl) {
            reportCtrl.hideNewCommentUi();
          }
        }


        scope.$watch(() => newCommentCtrl.commentContent, (newValue, oldValue) => {
          if (newValue && newValue !== oldValue) {
            newCommentCtrl.hasMeaningfulContent = nvUtil.hasMeaningfulContent(newValue);
          }
        });

        scope.$watch(() => newCommentCtrl.editable, (newValue, oldValue) => {
          if (newValue && newValue !== oldValue) {
            toggleScrollFocusRegistering(); // should scroll if not editing inline
          }
        });

        // have to watch because the report gets loaded in asynchronously
        scope.$watch(() => newCommentCtrl.report, (newValue, oldValue) => {
          if (newValue && (!oldValue || newValue.id !== oldValue.id)) {
            // console.log('report exists in new comment ctrl');
            registerCommentCallbacks();
            registerScrollFocusHandlers();
          }
        });

        scope.$on('$destroy', unregisterCommentCallbacks);


        function registerScrollFocusHandlers() {
          const $froalaElement = elem.find('[nv-froala-editor]');

          if ((newCommentCtrl.report || newCommentCtrl.post) && $froalaElement.length) {
            ScrollFocusConnectorFactory.register(
              $froalaElement,
              getScrollParent(),
              newCommentCtrl.post ? newCommentCtrl.post.id : newCommentCtrl.report.id,
              newCommentCtrl.post ? 'post' : 'report',
            );
          }
        }

        function focus() {
          ScrollFocusConnectorFactory.focus(
            newCommentCtrl.post ? newCommentCtrl.post.id : newCommentCtrl.report.id,
            newCommentCtrl.post ? 'post' : 'report',
            { offset: 150 },
          );
        }

        function toggleScrollFocusRegistering(shouldScroll) {
          if (shouldScroll || newCommentCtrl.editable) {
            $timeout(() => {
              registerScrollFocusHandlers();
              focus();
            }, 0);
          } else if ((newCommentCtrl.post || newCommentCtrl.report)) {
            // deregister scroll focus handler
            ScrollFocusConnectorFactory.deregister(
              newCommentCtrl.post ? newCommentCtrl.post.id : newCommentCtrl.report.id,
              newCommentCtrl.post ? 'post' : 'report',
            );
          }
        }

        function handleCommentActions(comment, actionType) {
          if (actionType === 'edit') {
            newCommentCtrl.editComment(comment);
          } else if (actionType === 'delete') {
            newCommentCtrl.reset();
          }
        }

        function registerCommentCallbacks() {
          if (postCtrl) {
            postCtrl.registerCommentActionHandlers(newCommentCtrl.handleCommentActions);
          } else if (reportCtrl) {
            reportCtrl.registerCommentActionHandlers(newCommentCtrl.handleCommentActions);
          }
        }

        function unregisterCommentCallbacks() {
          if (postCtrl) {
            postCtrl.unregisterCommentActionHandlers();
          } else if (reportCtrl) {
            reportCtrl.unregisterCommentActionHandlers();
          }
        }

        function getScrollParent() {
          if (!newCommentCtrl.report) {
            return angular.element($uibPosition.scrollParent(elem));
          }

          if (nvCurrentPage.getCurrentStateData().level < 4) {
            return $('.lightbox-panel');
          }

          return $('.modal-content-wrapper');
        }

        scope.$on('$destroy', () => {
          ScrollFocusConnectorFactory.deregister(
            newCommentCtrl.post ? newCommentCtrl.post.id : newCommentCtrl.report.id,
            newCommentCtrl.post ? 'post' : 'report',
          );
        });
      },
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'discussions/templates/nv-discussion-new-comment.html',
  };
}
