import React from 'react';
import last from 'lodash/last';

import t from 'react-translate';
import { QuestionContext } from 'quizzes/components/context';
import { getAlphabetCharacter } from 'quizzes/components/utils';
import { QuizQuestionType } from 'redux/schemas/models/progressive-quiz';

type Props = {
  className?: string;
};

const CorrectAnswer = (props: Props) => {
  const { className } = props;

  const { currentQuestion, responseOptions } = React.useContext(QuestionContext);

  const { correctAnswersCount } = currentQuestion;

  const isAdminNotSettedAnyCorrectAnswer = (currentQuestion.type === QuizQuestionType.MULTIPLE_CHOICE)
    && responseOptions.filter((option) => !option.isCorrect).length === 0;

  const correctAnswers = responseOptions.map((option, index) => ({
    optionIdentifier: getAlphabetCharacter(index),
    option,
  })).filter(({ option }) => option.isCorrect).map(({ optionIdentifier }) => optionIdentifier);

  const renderCorrectAnswers = () => {
    if (correctAnswersCount) {
      return correctAnswers.join(', ');
    }

    const lastCorrectAnswer = last(correctAnswers);

    const before = correctAnswers.slice(0, correctAnswers.length - 1);

    if (isAdminNotSettedAnyCorrectAnswer) {
      return `${before.join(', ')}${before.length ? t.JOIN.MANY.OR() : ''}${lastCorrectAnswer}`;
    }

    return `${before.join(', ')}${before.length ? ', ' : ''}${lastCorrectAnswer}`;
  };

  return (
    <div className={`text-body-large bold text-success ${className ?? ''}`}>
      {t.QUIZZES.CORRECT_ANSWER()}
      {' '}
      {renderCorrectAnswers()}
    </div>
  );
};

export default CorrectAnswer;
