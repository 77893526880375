import React from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import NvFroala from 'froala/components/nv-froala';
import { AngularServicesContext } from 'react-app';
import NvTooltip from 'shared/components/nv-tooltip';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import ClickableContainer from 'components/clickable-container';
import { getAlphabetCharacter } from 'quizzes/components/utils';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import { ReactComponent as CheckedBox } from 'styles/icons/checked-box.svg';
import { ReactComponent as UncheckedBox } from 'styles/icons/unchecked-box.svg';
import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { MultipleChoiceResponseOption, QuizQuestionType } from 'redux/schemas/models/progressive-quiz';
import {
  editQuizQuestionOption,
  deleteQuizQuestionOption,
  addQuestionOptionFeedback,
  EditQuizQuestionOptionParams,
  editQuestionOptionFeedback,
  deleteQuestionOptionFeedback,
} from 'redux/actions/quizzes';
import {
  gray4,
  gray5,
  gray7,
  danger,
  primary,
  success,
  hexToRgbaString,
} from 'styles/global_defaults/colors';
import {
  ReactComponent as SelectedRadio,
} from 'styles/icons/selected-radio.svg';
import {
  ReactComponent as UnselectedRadio,
} from 'styles/icons/unselected-radio.svg';
import {
  halfSpacing,
  largeSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { isEmpty } from 'underscore';
import { config } from '../../../config/pendo.config.json';

const defaultOptionTemplate = '<p class="froala-style-medium"><br /></p>';

type Props = {
  index: number;
  isDeleteDisabled: boolean;
  option: MultipleChoiceResponseOption;
};

const QuizQuestionOption = (props: Props) => {
  const {
    index,
    option,
    isDeleteDisabled,
  } = props;

  const dispatch = useAppDispatch();
  const mountedRef = React.useRef(false);
  const courseAliases = useSelector(getFlatCourseAliases);
  const [isFocused, setIsFocused] = React.useState(false);
  const angularServices = React.useContext(AngularServicesContext);
  const { registerSaving } = React.useContext(SavingRegistryContext);
  const [isFeedbackFocused, setIsFeedbackFocused] = React.useState(false);
  const [optionContent, setOptionContent] = React.useState(option.optionContent || defaultOptionTemplate);
  const [optionFeedback, setOptionFeedback] = React.useState(option.optionFeedback || defaultOptionTemplate);

  const {
    optionsState,
    setOptionState,
    currentQuestion,
    areAllOptionsCorrect,
    currentQuestionResponse,
  } = React.useContext(QuestionContext);

  const status = currentQuestionResponse?.feedback.includes(option.id);
  const hasStatus = !!status;
  const isIncorrect = !option.isCorrect;

  const selected = !!optionsState[option.id];

  const {
    mode,
    canMakeStructuralChanges,
    setShowSavingContainer,
    setIsQuizSaving,
    setIsSaved,
    timeoutRef,
  } = React.useContext(ProgressiveQuizContext);

  const isEditMode = mode === ProgressiveQuizMode.EDIT;
  const isNotEditMode = mode !== ProgressiveQuizMode.EDIT;
  const isNotAnswerMode = mode !== ProgressiveQuizMode.ANSWER;

  const editNotFocusedBorderColor = option.isCorrect ? success : gray5;

  const handleChange = React.useCallback((patch: EditQuizQuestionOptionParams['patch']) => {
    clearTimeout(timeoutRef.current);
    const unregister = registerSaving();
    setShowSavingContainer(true);
    setIsQuizSaving(true);

    dispatch(editQuizQuestionOption({
      patch,
      id: option.id,
    })).then((res) => {
      setIsQuizSaving(false);
      if (isEmpty(res.error)) {
        setIsSaved(true);
      } else {
        setIsSaved(false);
      }
    }).finally(() => {
      unregister();
      timeoutRef.current = setTimeout(() => {
        setShowSavingContainer(false);
      }, 2000);
    });
  }, [
    dispatch,
    option.id,
    registerSaving,
    setIsQuizSaving,
    setIsSaved,
    setShowSavingContainer,
    timeoutRef,
  ]);

  const handleDelete = () => {
    const unregister = registerSaving();
    setShowSavingContainer(true);
    setIsQuizSaving(true);
    clearTimeout(timeoutRef.current);

    dispatch(deleteQuizQuestionOption(option.id)).then((res) => {
      setIsQuizSaving(false);
      if (isEmpty(res.error)) {
        setIsSaved(true);
      } else {
        setIsSaved(false);
      }
    }).finally(() => {
      unregister();
      timeoutRef.current = setTimeout(() => {
        setShowSavingContainer(false);
      }, 2000);
    });
  };

  const withStatusColor = isIncorrect ? danger : success;

  const color = hasStatus ? withStatusColor : primary;

  const notEditSelectedBackgroundColor = hexToRgbaString(color, 0.05);

  const isSelectionDisabled = !!currentQuestionResponse;

  React.useEffect(() => {
    if (mountedRef.current) {
      handleChange({ optionContent });
    }
  }, [optionContent, handleChange]);

  React.useEffect(() => {
    if (mountedRef.current) {
      const unregister = registerSaving();
      setShowSavingContainer(true);
      setIsQuizSaving(true);
      clearTimeout(timeoutRef.current);

      dispatch(editQuestionOptionFeedback({
        questionOptionId: option.id,
        feedback: optionFeedback,
      })).then((res) => {
        setIsQuizSaving(false);
        if (isEmpty(res.error)) {
          setIsSaved(true);
        } else {
          setIsSaved(false);
        }
      }).finally(() => {
        timeoutRef.current = setTimeout(() => {
          setShowSavingContainer(false);
        }, 2000);
        unregister();
      });
    }
  }, [
    optionFeedback,
    dispatch,
    option.id,
    registerSaving,
    setShowSavingContainer,
    setIsQuizSaving,
    setIsSaved,
    timeoutRef,
  ]);

  React.useEffect(() => {
    mountedRef.current = true;
  }, []);

  const styles = css`
    background-color: white;

    .handle-container {
      width: ${largeSpacing}px;
      left: -${largeSpacing}px;

      .handle {
        visibility: hidden;
      }
    }

    &:hover {
      .handle {
        visibility: visible;
      }
    }

    .option-container {
      flex: 1;
      border-radius: 4px;
      ${isEditMode ? css`
        border: 1px solid ${isFocused ? primary : editNotFocusedBorderColor};
      ` : css`
        background-color: ${selected ? notEditSelectedBackgroundColor : gray7};
        ${selected && css`
          border: 1px solid ${color};
        `};
      `};

      .question-option-content {
        flex: 1;

        .fr-element {
          outline: 0!important;
          padding-top: 0!important;

          p:last-child {
            margin-bottom: 0;
          }

          ${isEditMode && option.isCorrect && css`
            color: ${success};
          `};
        }
      }

      .question-option-content-view {
        flex: 1;
      }

      .edit-options {
        top: 0;
        right: 0;
        opacity: 0;
      }

      .answer-checkbox {
        opacity: 0;
      }

      &:hover {
        ${mode === ProgressiveQuizMode.ANSWER && !isSelectionDisabled && css`
          .answer-checkbox {
            opacity: 1;
          }

          .big-checkbox-alphabet-character {
            font-weight: bold;
          }
        `};

        .edit-options {
          opacity: 1;
        }
      }
    }

    .feedback-container {
      padding-right: ${threeQuartersSpacing}px;
      .feedback-content {
        .fr-element {
          p:last-child {
            margin-bottom: 0;
          }
        }
      }

      .feedback-icon {
        color: ${option.isCorrect ? success : danger};
      }

      .feedback-content-container {
        flex: 1;
        min-height: ${largeSpacing}px;
        border: 1px solid transparent;

        ${!option.optionFeedback && css`
          border-color: ${gray4};
          background-color: ${gray7};
        `};

        ${isFeedbackFocused && css`
          border-color: ${primary};
          background-color: transparent;
        `};

        .feedback-content {
          flex: 1;

          .fr-element {
            overflow-y: hidden;
            border: 0!important;
            outline: 0!important;
            padding-top: 0!important;
            padding-bottom: 0!important;
            background-color: transparent!important;
            padding-left: ${halfSpacing}px!important;
            padding-right: ${halfSpacing}px!important;
            color: ${((isEditMode ? (option.isCorrect || areAllOptionsCorrect) : option.isCorrect)) ? success : danger};

            p:last-child {
              margin-bottom: 0;
            }
          }

          .fr-placeholder {
            border: 0!important;
          }
        }
      }
    }
  `;

  const isDeleteOptionDisabled = !canMakeStructuralChanges;

  const defaultFeedback = option.isCorrect ? t.SHARED.CORRECT() : t.SHARED.INCORRECT();

  const actualOptionFeedback = isEditMode ? option.optionFeedback : (optionFeedback || defaultFeedback);

  return (
    <div css={styles}>
      <div className='d-flex flex-row position-relative'>
        {isEditMode && (
          <div className='handle-container position-absolute h-100 d-flex align-items-center'>
            {/* Reusing this translation to not create a new one */}
            <NvTooltip text={t.LECTURE_PAGES.COMPONENTS.ACCORDION.DRAG_AND_DROP_TO_REORDER()}>
              <ClickableContainer
                className='handle'
                data-qa={config.pendo.activities.progressiveQuiz.questionModal.optionReorder}
                data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.optionReorder}_${option.id}`}
              >
                <NvIcon icon='drag-handle' size='small' />
              </ClickableContainer>
            </NvTooltip>
          </div>
        )}
        <ClickableContainer
          layoutOnly={isNotAnswerMode}
          disabled={isSelectionDisabled}
          onClick={() => setOptionState(option.id, !selected)}
          className='d-flex align-items-center p-3 option-container'
          data-qa={config.pendo.activities.progressiveQuiz.questionModal.option}
          data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.option}_${option.id}`}
        >
          <BigCheckbox
            mode={mode}
            index={index}
            color={color}
            className='mr-2'
            selected={selected}
            checked={option.isCorrect}
            characterClassName='big-checkbox-alphabet-character'
            onChange={(newCorrect) => handleChange({ isCorrect: newCorrect })}
            optionId={option.id}
          />
          {isEditMode ? (
            <NvFroala
              allowToolbar
              value={optionContent}
              minHeight={threeQuartersSpacing}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              className='question-option-content'
              placeholder={t.QUIZZES.INPUT_OPTION()}
              editorClass='no-background no-padding'
              onChange={(html) => setOptionContent(html)}
              dataQa={config.pendo.activities.progressiveQuiz.questionModal.optionContent}
              dataQaId={`${config.pendo.activities.progressiveQuiz.questionModal.optionContent}_${option.id}`}
            />
          ) : (
            <div className='question-option-content'>
              <ResponsivelyEmbeddedAngularHTML
                template={option.optionContent}
                angularServices={angularServices}
                className='fr-element question-option-content-view'
              />
            </div>
          )}
          {isEditMode && (
            <div className='d-flex align-items-center justify-content-center h-100 ml-2 edit-options'>
              {option.optionFeedback === null && (
              <NvTooltip text={t.QUIZZES.ADD_FEEDBACK(courseAliases)}>
                <ClickableContainer
                  className='mr-2'
                  onClick={() => {
                    const unregister = registerSaving();

                    dispatch(addQuestionOptionFeedback({
                      questionOptionId: option.id,
                      feedback: '',
                    })).finally(() => {
                      unregister();
                      setOptionFeedback('');
                    });
                  }}
                  data-qa={config.pendo.activities.progressiveQuiz.questionModal.optionAddHint}
                  data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.optionAddHint}_${option.id}`}
                >
                  <NvIcon icon='comments' size='small' className='text-primary' />
                </ClickableContainer>
              </NvTooltip>
              )}
              {!isDeleteDisabled && (
                <NvTooltip text={isDeleteOptionDisabled ? t.QUIZZES.ALREADY_RELEASED.OPTION_DELETE(courseAliases) : t.NOVOED.DELETE()}>
                  <ClickableContainer
                    onClick={handleDelete}
                    disabled={isDeleteOptionDisabled}
                    data-qa={config.pendo.activities.progressiveQuiz.questionModal.optionDelete}
                    data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.optionDelete}_${option.id}`}
                  >
                    <NvIcon icon='trash' size='small' className={isDeleteOptionDisabled ? 'gray-4' : 'text-primary'} />
                  </ClickableContainer>
                </NvTooltip>
              )}
            </div>
          )}
          {isNotEditMode && (
            <SelectedIndicator
              color={color}
              selected={selected}
              questionType={currentQuestion.type}
              unselectedClassName='answer-checkbox'
            />
          )}
        </ClickableContainer>
      </div>
      {actualOptionFeedback !== null && (
        <>
          {isEditMode ? (
            <div className='d-flex align-items-center feedback-container mt-2'>
              <div className='d-flex align-items-center feedback-content-container'>
                <NvFroala
                  allowToolbar
                  value={optionFeedback}
                  editorClass='no-padding'
                  className='feedback-content'
                  minHeight={threeQuartersSpacing}
                  onBlur={() => setIsFeedbackFocused(false)}
                  onFocus={() => setIsFeedbackFocused(true)}
                  onChange={(html) => setOptionFeedback(html)}
                  placeholder={(option.isCorrect || areAllOptionsCorrect) ? t.SHARED.CORRECT() : t.SHARED.INCORRECT()}
                  dataQa={config.pendo.activities.progressiveQuiz.questionModal.optionHintContent}
                  dataQaId={`${config.pendo.activities.progressiveQuiz.questionModal.optionHintContent}_${option.id}`}
                />
              </div>
              <NvTooltip text={t.NOVOED.DELETE()}>
                <ClickableContainer
                  className='ml-3'
                  onClick={() => {
                    const unregister = registerSaving();
                    setShowSavingContainer(true);
                    setIsQuizSaving(true);
                    clearTimeout(timeoutRef.current);

                    dispatch(deleteQuestionOptionFeedback(option.id)).then((res) => {
                      setIsQuizSaving(false);
                      if (isEmpty(res.error)) {
                        setIsSaved(true);
                      } else {
                        setIsSaved(false);
                      }
                    }).finally(() => {
                      unregister();
                      timeoutRef.current = setTimeout(() => {
                        setShowSavingContainer(false);
                      }, 2000);
                    });
                  }}
                  data-qa={config.pendo.activities.progressiveQuiz.questionModal.optionHintDelete}
                  data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.optionHintDelete}_${option.id}`}
                >
                  <NvIcon icon='trash' size='small' className='text-primary' />
                </ClickableContainer>
              </NvTooltip>
            </div>
          ) : (hasStatus && (
            <div className='d-flex feedback-container mt-2 align-items-center'>
              <div className='pl-1 mr-2'>
                <NvIcon
                  size='small'
                  className='feedback-icon'
                  icon={option.isCorrect ? 'success' : 'error'}
                />
              </div>
              <div className='feedback-content'>
                <ResponsivelyEmbeddedAngularHTML
                  className={`fr-element froala-style-medium ${option.isCorrect ? 'text-success' : 'text-danger'}`}
                  template={!isEmpty(option.optionFeedback) ? option.optionFeedback : `<p>${defaultFeedback}</p>`}
                  angularServices={angularServices}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

type BigCheckboxProps = {
  index: number;
  color: string;
  checked: boolean;
  selected: boolean;
  mode: ProgressiveQuizMode;
  onChange: (newChecked) => void;
  className?: string;
  characterClassName?: string,
  optionId: number,
};

const BigCheckbox = (props: BigCheckboxProps) => {
  const {
    mode,
    index,
    color,
    selected,
    onChange,
    className,
    checked = false,
    characterClassName,
    optionId,
  } = props;

  const editNotFocusedBorderColor = checked ? success : gray5;

  const styles = css`
    border-radius: 4px;
    width: ${largeSpacing}px;
    height: ${largeSpacing}px;
    border: 1px solid ${gray5};

    .icon {
      color: ${success};
    }

    ${mode === ProgressiveQuizMode.EDIT ? css`
      border: 1px solid ${editNotFocusedBorderColor};
    ` : css`
      ${selected && css`
        border: 1px solid ${color};
        .text-body-large {
          color: ${color};
        }
      `};
    `};
  `;

  return (
    <NvTooltip
      text={t.QUIZZES.MAKE_CORRECT_ANSWER()}
      enabled={mode === ProgressiveQuizMode.EDIT && !checked}
    >
      <ClickableContainer
        css={styles}
        onClick={() => onChange(!checked)}
        layoutOnly={mode !== ProgressiveQuizMode.EDIT}
        className={`align-items-center justify-content-center ${className}`}
        data-qa={config.pendo.activities.progressiveQuiz.questionModal.optionCorrectCheckbox}
        data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.optionCorrectCheckbox}_${optionId}`}
      >
        {mode === ProgressiveQuizMode.EDIT ? (checked && (
          <NvIcon icon='check' size='smallest' className='d-flex' />
        )) : (
          <div className={`text-body-large gray-1 ${characterClassName}`}>
            {getAlphabetCharacter(index)}
          </div>
        )}
      </ClickableContainer>
    </NvTooltip>
  );
};

type SelectedIndicatorProps = {
  color: string;
  selected: boolean;
  unselectedClassName?: string;
  questionType: QuizQuestionType;
};

const SelectedIndicator = (props: SelectedIndicatorProps) => {
  const { color, selected, questionType, unselectedClassName } = props;

  const isMultipleAnswer = questionType === QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER;

  const commonProps = {
    width: largeSpacing,
    height: largeSpacing,
    color: selected ? color : gray5,
  };

  if (isMultipleAnswer) {
    return selected
      ? <CheckedBox {...commonProps} /> : <UncheckedBox {...commonProps} className={unselectedClassName} />;
  }

  return selected ? <SelectedRadio {...commonProps} /> : <UnselectedRadio {...commonProps} className={unselectedClassName} />;
};

export default QuizQuestionOption;
