import React, {} from 'react';
import { NQuizQuestion } from 'redux/schemas/models/progressive-quiz';

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion;
  className?: string;
};
const NumberAnswerQuizQuestion = (props: QuizQuestionProps) => (
  <div>
    {/* TO DO Number answer */}
  </div>
);

export default NumberAnswerQuizQuestion;
