import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { objectToFormData } from 'shared/utils';
import { Enrollment } from 'redux/schemas/models/my-account';
import { Result } from 'redux/schemas/api';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import t from 'react-translate';
import { Course } from 'redux/schemas/models/course';
import { VisitedLearningJourneySchema } from 'redux/schemas/app/learning-journey';
import { addAlertMessage } from './alert-messages';

export const fetchJourney = createAsyncThunk<{
  journey: Course,
  shouldSetCourse: boolean,
}, {
  catalogId: string,
  shouldSetCourse?: boolean,
  isEditing?: boolean,
}>('FETCH_JOURNEY', async (
  params,
  thunkAPI,
) => {
  const {
    catalogId,
    shouldSetCourse = false,
    isEditing = false,
  } = params;

  try {
    const journey = (await axios.get(`/journeys/${catalogId}.json`, {
      params: isEditing ? { edit: true } : {},
    })).data.result;

    return {
      journey,
      shouldSetCourse,
    };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.status);
  }
});

export const createJourney = createAsyncThunk('CREATE_JOURNEY', (params: any, thunkAPI) => {
  const formData = objectToFormData({
    course: params,
  });

  return axios.post('/courses', formData).then(
    (response) => response.data.result,
  ).catch((e) => thunkAPI.rejectWithValue(e.response.data.error));
});

export const updateJourney = createAsyncThunk('UPDATE_JOURNEY', (params: any, thunkAPI) => {
  const formData = objectToFormData({
    course: params,
  });

  return axios.put(`/courses/${params.id}`, formData).then(
    (response) => response.data.result,
  ).catch((e) => thunkAPI.rejectWithValue(e.response.data.error));
});

export const updateJourneyCollections = createAsyncThunk('UPDATE_JOURNEY_COLLECTIONS', async (params: any) => {
  const {
    catalogId,
    collections,
    description,
    completionSettings,
  } = params;

  return (await axios.post(`/journeys/${catalogId}/collections`, {
    description,
    collections,
    completionSettings,
  })).data.result;
});

export const enrollInJourney = createAsyncThunk('ENROLL_IN_JOURNEY', async (params: { catalogId: string }, thunkAPI) => {
  const { catalogId } = params;

  return axios.get<Result<Enrollment>>(`/${catalogId}/enroll.json`)
    .then(response => {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.LEARNING_JOURNEYS.DETAILS.ENROLLMENT_DATA.SUCCESS.HEADER(),
        message: t.LEARNING_JOURNEYS.DETAILS.ENROLLMENT_DATA.SUCCESS.MESSAGE(),
      }));

      return response.data.result;
    });
});

export const validateActivityCode = createAsyncThunk('VALIDATE_ACTIVITY_CODE', async (params: any) => {
  const { lmsActivityCode, institutionId } = params;
  const url = `/institutions/${institutionId}/lms_connections/validate_lms_activity`;
  return (await axios.post(url, { lmsActivityCode })).data.result;
});

export const updateVisitedJourney = createAction<VisitedLearningJourneySchema>('UPDATE_VISITED_JOURNEY');
