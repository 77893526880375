import React from 'react';
import { css } from '@emotion/core';
import { useMediaQuery } from 'react-responsive';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import ProgressiveQuizContext from 'quizzes/components/context';
import ClickableContainer from 'components/clickable-container';
import { screenSmMin } from 'styles/global_defaults/media-queries';
import { gray2, gray3, gray5, white } from 'styles/global_defaults/colors';
import { largeSpacing, doubleSpacing } from 'styles/global_defaults/scaffolding';
import useProgressiveQuizNavigation from 'quizzes/hooks/use-progressive-quiz-navigation';
import { config } from '../../../config/config.json';

const NavigationArrows = () => {
  const {
    next,
    previous,
    isNextDisabled,
    isPreviousDisabled,
  } = useProgressiveQuizNavigation();

  return (
    <>
      <ArrowButton onClick={previous} disabled={isPreviousDisabled} />
      <ArrowButton onClick={next} next disabled={isNextDisabled} />
    </>
  );
};

type ArrowButtonProps = {
  next?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const ArrowButton = (props: ArrowButtonProps) => {
  const { next = false, onClick, disabled = false } = props;

  const { mode, isSubmitting } = React.useContext(ProgressiveQuizContext);

  const isAnswerMode = mode === ProgressiveQuizMode.ANSWER;

  const isMobile = useMediaQuery({
    query: `(max-width: ${screenSmMin}px)`,
  });

  const commonStyles = css`
    flex-shrink: 0;
    width: ${doubleSpacing}px;
    height: ${doubleSpacing}px;
  `;

  const styles = css`
    top: 0;
    width: 0;
    ${next ? css`
      right: 0;
    ` : css`
      left: 0;
    `};

    .arrow-button {
      ${isMobile ? css`
        opacity: 0.8;
        background-color:  ${gray2};
        ${next && css`
          transform: translateX(-${doubleSpacing}px);
        `};
        ${commonStyles};

        .icon {
          color: ${white};
        }
      ` : css`
        ${next ? css`
        transform: translateX(-${largeSpacing + doubleSpacing}px);
        ` : css`
        transform: translateX(${largeSpacing}px);
        `};
        ${commonStyles};

        .icon {
          font-size: ${doubleSpacing}px;
          color: ${disabled ? gray5 : gray3};
        }
      `};
    }
  `;

  if (isMobile && disabled) {
    return null;
  }

  return (
    <div className='position-absolute h-100 d-flex align-items-center' css={styles}>
      <NvTooltip
        placement='bottom'
        enabled={next && isAnswerMode && disabled && !isSubmitting}
        text={t.QUIZZES.CANNOT_MOVE_FORWARD_TOOLTIP()}
      >
        <ClickableContainer
          onClick={onClick}
          disabled={disabled}
          aria-label={next ? t.SHARED.NEXT() : t.SHARED.PREVIOUS()}
          className='arrow-button align-items-center justify-content-center'
          data-qa={config.pendo.activities.progressiveQuiz.questionModal[next ? 'nextQuestionIcon' : 'previousQuestionIcon']}
        >
          <NvIcon
            size={isMobile ? 'small' : 'larger'}
            icon={`arrow-${next ? 'right' : 'left'}`}
          />
        </ClickableContainer>
      </NvTooltip>
    </div>
  );
};

export default NavigationArrows;
