import { css } from '@emotion/core';
import React, { CSSProperties } from 'react';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import useFocusEvent from 'shared/hooks/use-focus-event';
import useUploadFile from 'shared/hooks/use-upload-file';
import { editQuizQuestion } from 'redux/actions/quizzes';
import NvFilePicker from 'shared/components/nv-filepicker';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import { addAlertMessage } from 'redux/actions/alert-messages';
import ClickableContainer from 'components/clickable-container';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import NvFileUploadProgress from 'shared/components/nv-file-upload-progress';
import ValidationErrorMessage from 'shared/components/validation-error-message';
import { standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import { black, danger, gray3, gray7, hexToRgbaString } from 'styles/global_defaults/colors';
import { isEmpty } from 'underscore';
import { config } from '../../../config/config.json';

const ALLOWED_IMAGE_TYPES = config.files.images.split(',');
const MAX_FILE_SIZE = 52428800;

type Props = {
  className?: string;
  handleImageLoad?: Function;
};

const QuizImage = (props: Props) => {
  const { className, handleImageLoad } = props;

  const dispatch = useAppDispatch();
  const imageContainerRef = React.useRef();
  const [isMouseIn, setIsMouseIn] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const {
    mode,
    setIsQuizSaving,
    setShowSavingContainer,
    setIsSaved,
    timeoutRef,
  } = React.useContext(ProgressiveQuizContext);
  const { currentQuestion } = React.useContext(QuestionContext);
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const [showFullScreen, setShowFullScreen] = React.useState(false);
  const { registerSaving } = React.useContext(SavingRegistryContext);
  const [imageStyle, setImageStyle] = React.useState<Partial<CSSProperties>>({ display: 'none' });
  const { uploadFiles, isUploading, hasUploadPercentage, filesUploading, abortUpload } = useUploadFile();

  const src = currentQuestion.picture?.cdnUrl;

  const isEditMode = mode === ProgressiveQuizMode.EDIT;

  React.useEffect(() => {
    if (!src) {
      setIsImageLoaded(false);
    }
  }, [src]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (isUploading) {
      return registerSaving();
    }
  }, [isUploading, registerSaving]);

  useFocusEvent(imageContainerRef, 'focus', () => setIsFocused(true), []);

  useFocusEvent(imageContainerRef, 'blur', () => setIsFocused(false), []);

  const showOverlay = isMouseIn || isFocused;

  const styles = css`
    ${src && isEditMode && css`
      width: 360px;
      height: 360px;
    `};

    .overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px dashed ${gray3};
      opacity: ${showOverlay ? 1 : 0};
      background-color: ${hexToRgbaString(gray7, 0.9)};

      .separator {
        width: 1px;
        background-color: ${gray3};
        height: ${standardSpacing}px;
      }

      .image-option:hover {
        color: ${black};
      }
      .delete-option:hover {
        color: ${danger};
      }
    }
  `;

  const handleImagePickerChange = async (files: File[]) => {
    const [{ name, size, type, uniqueId }] = await uploadFiles(files, S3NameSpaces.QUIZ_QUESTIONS);
    const unregister = registerSaving();

    setShowSavingContainer(true);
    setIsQuizSaving(true);
    clearTimeout(timeoutRef.current);

    dispatch(editQuizQuestion({
      id: currentQuestion.id,
      patch: {
        pictureFileName: name,
        pictureFileSize: size,
        pictureContentType: type,
        pictureUniqueId: uniqueId,
      },
    })).then((res) => {
      setIsQuizSaving(false);
      if (isEmpty(res.error)) {
        setIsSaved(true);
      } else {
        setIsSaved(false);
      }
    }).finally(() => {
      unregister();
      timeoutRef.current = setTimeout(() => {
        setShowSavingContainer(false);
      }, 2000);
    });
  };

  const wrapWithPicker = (children, extraCondition = true, pickerStyle?, wrapperStyle?) => (
    <NvPopover
      placement='top'
      css={wrapperStyle}
      show={showError && extraCondition}
      content={(
        <ValidationErrorMessage header={t.FORM.WARNING()} text={t.FILE_UPLOAD.IMAGE_EXCEEDS('50MB')} />
      )}
    >
      <NvFilePicker
        multiple={false}
        css={pickerStyle}
        maxSize={MAX_FILE_SIZE}
        accept={ALLOWED_IMAGE_TYPES}
        onChange={handleImagePickerChange}
        onClick={() => setShowError(false)}
        className={`d-flex align-items-center justify-content-center ${className}`}
        data-qa={config.pendo.activities.progressiveQuiz.questionModal.questionImage}
        onSelectError={(e) => {
          const exceeds = e.some(({ errors }) => errors.some(({ code }) => code === 'file-too-large'));
          if (exceeds) {
            setShowError(true);
            dispatch(addAlertMessage({
              header: t.FORM.OOPS(),
              type: AlertMessageType.ERROR,
              message: t.FILE_UPLOAD.SIZE_EXCEEDED_ALERT('50MB'),
            }));
          }
        }}
      >
        {children}
      </NvFilePicker>
    </NvPopover>
  );

  if (isUploading) {
    return (
      <NvFileUploadProgress
        abortUpload={abortUpload}
        css={css` min-width: 360px `}
        uploadInProgress={isUploading}
        fileUploading={filesUploading[0]}
        hasUploadPercentage={hasUploadPercentage}
      />
    );
  }

  return (
    src ? (
      <div
        css={styles}
        ref={imageContainerRef}
        onMouseEnter={() => setIsMouseIn(true)}
        onMouseLeave={() => setIsMouseIn(false)}
        className={`position-relative d-flex align-items-center justify-content-center ${className}`}
      >
        <NvModal
          doubleModal
          type={ModalType.FULL}
          show={showFullScreen}
          onClose={() => setShowFullScreen(false)}
          // NOTE: Using this translation to not create a new one
          header={t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.FULL_SCREEN()}
          body={(
            <img
              css={css`
                width: 100%;
              `}
              src={src}
              alt=''
            />
          )}
        />
        <ClickableContainer layoutOnly={isEditMode} onClick={() => setShowFullScreen(true)}>
          <img
            alt=''
            src={src}
            aria-label={t.QUIZZES.QUESTION_IMAGE()}
            style={imageStyle}
            onLoad={(e) => {
              setIsImageLoaded(true);
              handleImageLoad();

              const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
              if (naturalWidth >= naturalHeight) {
                setImageStyle({
                  width: '360px',
                  height: 'auto',
                });
              } else {
                setImageStyle({
                  width: 'auto',
                  height: '360px',
                });
              }
            }}
          />
        </ClickableContainer>
        {(isImageLoaded && isEditMode) && (
          <div className='h-100 w-100 flex-column position-absolute d-flex align-items-center justify-content-center overlay gray-3'>
            <div className='label gray-3 mb-2'>{t.FILE_UPLOAD.UPLOAD_IMAGE()}</div>
            <div className='text-small mb-4'>
              {t.FILE_UPLOAD.IMAGE_TYPES()}
              {' '}
              {t.FILE_UPLOAD.UP_TO_SIZE('50MB')}
            </div>
            <div className='d-flex align-items-center'>
              {wrapWithPicker(
                <div className='d-flex align-items-center image-option'>
                  <NvIcon icon='upload' size='small' className='mr-1' />
                  <div className='text-small'>
                    {/* Using org level profile string to reuse and not create a new translation */}
                    {t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.UPLOAD()}
                  </div>
                </div>,
                showOverlay,
              )}
              <div className='separator ml-6 mr-6' />
              <ClickableContainer
                className='align-items-center delete-option'
                onClick={() => {
                  dispatch(openConfirmationDialog({
                    title: t.SHARED.DELETE_IMAGE_CONFIRMATION(),
                    confirmText: t.FORM.YES_SURE(),
                    onConfirm: () => {
                      setShowError(false);

                      const unregister = registerSaving();
                      setShowSavingContainer(true);
                      setIsQuizSaving(true);
                      clearTimeout(timeoutRef.current);

                      dispatch(editQuizQuestion({ id: currentQuestion.id,
                        patch: {
                          pictureFileName: null,
                          pictureFileSize: null,
                          pictureContentType: null,
                          pictureUniqueId: null,
                        },
                      })).then((res) => {
                        setIsQuizSaving(false);
                        if (isEmpty(res.error)) {
                          setIsSaved(true);
                        } else {
                          setIsSaved(false);
                        }
                      }).finally(() => {
                        unregister();
                        timeoutRef.current = setTimeout(() => {
                          setShowSavingContainer(false);
                        }, 2000);
                      });
                    },
                  }));
                }}
              >
                <NvIcon icon='trash' size='small' className='mr-1' />
                <div className='text-small'>
                  {t.NOVOED.DELETE()}
                </div>
              </ClickableContainer>
            </div>
          </div>
        )}
      </div>
    ) : (isEditMode && wrapWithPicker(
      <>
        <NvIcon icon='upload' size='small' className='mr-4 gray-2' />
        <div>
          <div className='label gray-2'>{t.FILE_UPLOAD.UPLOAD_IMAGE()}</div>
          <div className='text-small gray-2'>
            {t.FILE_UPLOAD.IMAGE_TYPES()}
            {' '}
            {t.FILE_UPLOAD.UP_TO_SIZE('50MB')}
          </div>
        </div>
      </>,
      true,
      pickerStyles,
      popoverWrapperStyle,
    ))
  );
};

const pickerStyles = css`
  height: ${tripleSpacing}px;
  border: 1px dashed ${gray3};
`;

const popoverWrapperStyle = css`
  width: 100%;
  max-width: 360px;
`;

export default QuizImage;
