import React from 'react';

import { GetQuestionParams } from 'redux/actions/quizzes';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import {
  QuizQuestion,
  NQuizQuestion,
  NProgressiveQuiz,
  NQuizQuestionResponse,
  MultipleChoiceResponseOption,
} from 'redux/schemas/models/progressive-quiz';
import {
  ComponentType,
  NLectureComponent,
} from 'redux/schemas/models/lecture-component';

type ProgressiveQuizContextValue = {
  reveal: boolean;
  mode: ProgressiveQuizMode;
  questions: NQuizQuestion[];
  notMetRequirement: () => boolean;
  currentQuestionIndex: number;
  isRequirementEnabled: boolean;
  currentQuestion: NQuizQuestion;
  canMakeStructuralChanges: boolean;
  progressiveQuiz: NProgressiveQuiz;
  requiredCorrectQuestionsCount: number;
  closeModal: (skipCheck?: boolean) => void;
  scrollRef: React.MutableRefObject<HTMLDivElement>;
  setCurrentQuestionIndex: (newIndex: number) => void;
  currentQuestionResponse: NQuizQuestionResponse | null;
  forwardOnModalClose: (fn: (e: Event) => void) => () => void;
  lectureComponent: NLectureComponent<ComponentType.PROGRESSIVE_QUIZ>;
  fetchQuestion: (params?: Omit<GetQuestionParams, 'questionSetId'>) => Promise<QuizQuestion>;
  setShowSavingContainer: (showSavingContainer: boolean) => void;
  setIsQuizSaving: (isQuizSaving: boolean) => void;
  setIsSaved: (isSaved: boolean) => void;
  timeoutRef: React.MutableRefObject<null | NodeJS.Timeout>;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
};

export default React.createContext<ProgressiveQuizContextValue>(null);

type QuestionContextValue = {
  areAllOptionsCorrect?: boolean;
  currentQuestion: NQuizQuestion;
  optionsState?: Record<number, boolean>;
  responseOptions?: MultipleChoiceResponseOption[];
  currentQuestionResponse?: NQuizQuestionResponse | null;
  setOptionState?: (optionId: number, selected: boolean) => void;
};

export const QuestionContext = React.createContext<QuestionContextValue>(null);
