import { css } from '@emotion/react';
import React from 'react';
// spacing, colors
import { gray1, gray3, gray7, highTide, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
// components
import { Button } from 'react-bootstrap';

// types
type NvRoundButtonProps = {
  icon?: string,
  onClick?: (event: any) => void,
  className? : string,
  label?: string,
  disabled?: boolean,
  pendoTag?: string,
  ariaLabel?: string,
};

const buttonStyles = css`
 &.nv-icon-button{
  background-color: ${gray7};
  border:0;
  border-radius: ${standardSpacing}px;
  color:${primary};
  font-weight:400;
  height:${doubleSpacing}px;
  margin: 0 ${halfSpacing}px;
  &.disabled{
    color:${gray3};
  }
  &.icon-only{
    :hover{
      background-color:transparent;
    }
  }
  :hover{
   background-color: ${gray7};
   color: ${highTide};
  }
 }
`;
const NvRoundButton = (props: NvRoundButtonProps) => (
  <Button
    css={buttonStyles}
    className={`nv-icon-button ${props.disabled ? 'disabled' : ''} ${!props.label?.length ? 'icon-only' : ''} ${props.className}`}
    onClick={(e) => props.onClick(e)}
    disabled={props.disabled}
    pendo-tag-name={props.pendoTag}
    aria-label={props.ariaLabel}
  >
    {props.icon && <i className={props.icon} />} {props.label}
  </Button>
);

export default NvRoundButton;
