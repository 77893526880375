import React from 'react';
import { css, ClassNames } from '@emotion/react';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvAutocompleteBase from 'shared/components/nv-autocomplete-base';
import useLanguageTranslation from 'automated_translation/hooks/use-language-translation';
import {
  info,
  gray2,
  gray3,
  gray4,
  white,
  primary,
} from 'styles/global_defaults/colors';
import ClickableContainer, {
  ClickableContainerProps,
} from 'components/clickable-container';
import {
  doubleSpacing,
  halfSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { config } from '../../../config/pendo.config.json';

export type LanguageValue = {
  isoCode: string,
  label?: string,
  translationKey?: string,
  francLangCode?: string
};

// The 'francLangCode' attribute is added to keep consistency
// between the supported list of automated translation languages
// and the list of languages we want to restrict the detection to
// in the useAutoTranslate hook (using franc library).
// When adding a new LanguageValue item, we will need to provide
// the corresponding detectedLanguage from:
// https://github.com/wooorm/franc/tree/main/packages/franc#data
export const AUTOMATED_TRANSLATION_LANGUAGES: LanguageValue[] = [
  {
    translationKey: 'AR_SA',
    isoCode: 'ar',
    francLangCode: 'arb' // Arabic
  },
  {
    translationKey: 'ZH_CN',
    isoCode: 'zh-hans',
    francLangCode: 'cmn' // Chinese
  },
  {
    translationKey: 'NL_NL',
    isoCode: 'nl',
    francLangCode: 'nld' // Dutch
  },
  {
    translationKey: 'EN_US',
    isoCode: 'en',
    francLangCode: 'eng' // English
  },
  {
    translationKey: 'FR_FR',
    isoCode: 'fr',
    francLangCode: 'fra' // French
  },
  {
    translationKey: 'FR_CA',
    isoCode: 'fr-ca',
    francLangCode: 'fra' // French
  },
  {
    translationKey: 'DE_DE',
    isoCode: 'de',
    francLangCode: 'deu' // German
  },
  {
    translationKey: 'HE_IL',
    isoCode: 'he',
    francLangCode: 'heb' // Hebrew
  },
  {
    translationKey: 'ID',
    isoCode: 'id',
    francLangCode: 'ind' // Indonesian
  },
  {
    translationKey: 'IT_IT',
    isoCode: 'it',
    francLangCode: 'ita' // Italian
  },
  {
    translationKey: 'JA_JP',
    isoCode: 'ja',
    francLangCode: 'jpn' // Japanese
  },
  {
    translationKey: 'KO_KP',
    isoCode: 'ko',
    francLangCode: 'kor' // Korean
  },
  {
    translationKey: 'PL_PL',
    isoCode: 'pl',
    francLangCode: 'pol' // Polish
  },
  {
    translationKey: 'PT_PT',
    isoCode: 'pt-pt',
    francLangCode: 'por' // Portuguese
  },
  {
    translationKey: 'PT_BR',
    isoCode: 'pt',
    francLangCode: 'por' // Portuguese
  },
  {
    translationKey: 'RO_RO',
    isoCode: 'ro',
    francLangCode: 'ron' // Romanian
  },
  {
    translationKey: 'RU_RU',
    isoCode: 'ru',
    francLangCode: 'rus' // Russian
  },
  {
    translationKey: 'SV_SE',
    isoCode: 'sv',
    francLangCode: 'swe' // Swedish
  },
  {
    translationKey: 'ES_ES',
    isoCode: 'es',
    francLangCode: 'spa' // Spanish
  },
  {
    translationKey: 'TH',
    isoCode: 'th',
    francLangCode: 'tha' // Thai
  },
  {
    translationKey: 'TR',
    isoCode: 'tr',
    francLangCode: 'tur' // Turkish
  },
  {
    translationKey: 'VI',
    isoCode: 'vi',
    francLangCode: 'vie' // Vietnamese
  },
];

type Props = {
  dataQa?: string,
  className?: string,
  value?: LanguageValue,
  onChange?: (newLanguage: LanguageValue) => void,
};

const LanguagePicker = (props: Props) => {
  const {
    value,
    dataQa,
    onChange,
    className,
  } = props;

  const getLanguageTranslation = useLanguageTranslation();

  const languages = React.useMemo<LanguageValue[]>(() => AUTOMATED_TRANSLATION_LANGUAGES.map((each) => ({
    isoCode: each.isoCode,
    label: getLanguageTranslation(each.translationKey),
  })), [getLanguageTranslation]);

  const overlayStyles = css`
    max-height: 310px;
    background-color: ${white};
  `;

  const getLanguagePredictions = (hint) => {
    if (!hint) {
      return languages;
    }

    return languages.filter((language) => language.label.toLowerCase().includes(hint.toLowerCase()));
  };

  return (
    <ClassNames>
      {({ css: innerCss }) => (
        <NvAutocompleteBase<LanguageValue>
          debounce={0}
          value={value}
          placement='bottom'
          offset={[0, 1]}
          onChange={onChange}
          className={className}
          renderNoResults={() => <NoResults />}
          fetchPredictions={getLanguagePredictions}
          resultsContainerClassName={innerCss`${overlayStyles}`}
          renderResultItem={(itemProps, currentValue) => (
            <PickerOption
              value={currentValue}
              data-qa={`${config.pendo.automatedTranslation.languagePrefix}_${currentValue.isoCode.toUpperCase()}`}
              {...itemProps}
            />
          )}
          renderInput={(inputProps) => (
            <PickerInput data-qa={dataQa} {...inputProps} />
          )}
          renderValueSelected={(valueProps, selectedValue) => (
            <PickerValue
              data-qa={dataQa}
              value={selectedValue}
              {...valueProps}
            />
          )}
        />
      )}
    </ClassNames>
  );
};

const DropdownArrow = () => {
  const styles = css`
    top: 14px;
    right: 14px;
    color: ${gray3};
    position: absolute;
  `;

  return (
    <NvIcon
      css={styles}
      size='xss-smallest'
      icon='dropdown-arrow'
    />
  );
};

type PickerInputProps = Omit<React.ComponentProps<'input'>, 'type'>;

const PickerInput = React.forwardRef<HTMLInputElement, PickerInputProps>((props, ref) => {
  const { onBlur, onFocus, className, ...restProps } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const styles = css`
    position: relative;
    border-radius: 4px;
    border: 1px solid ${gray4};
    height: ${doubleSpacing}px;

    ${isFocused && css`
      border-color: ${primary};
    `};

    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      line-height: ${standardSpacing}px;
      padding: 0 ${doubleSpacing}px 0 ${halfSpacing}px;

      ::placeholder {
        color: ${gray2};
      }
    }
  `;

  const handleInputBlur = (event) => {
    onBlur?.(event);

    setIsFocused(false);
  };

  const handleInputFocus = (event) => {
    onFocus?.(event);

    setIsFocused(true);
  };

  return (
    <div css={styles} className={className}>
      <input
        ref={ref}
        type='text'
        onBlur={handleInputBlur}
        className='text-regular'
        onFocus={handleInputFocus}
        placeholder={t.AUTOMATED_TRANSLATION.PLEASE_SELECT()}
        {...restProps}
      />
      <DropdownArrow />
    </div>
  );
});

type PickerValueProps = ClickableContainerProps & {
  value: LanguageValue,
};

const PickerValue = React.forwardRef<HTMLDivElement, PickerValueProps>((props, ref) => {
  const {
    value,
    ...restProps
  } = props;

  const styles = css`
    position: relative;
    border-radius: 4px;
    align-items: center;
    border: 1px solid ${gray4};
    height: ${doubleSpacing}px;
    padding: 0 ${doubleSpacing}px 0 ${halfSpacing}px;

    .language {
      flex: 1;
    }
  `;

  return (
    <ClickableContainer
      ref={ref}
      css={styles}
      className='text-regular'
      {...restProps}
    >
      <div className='ellipsis language'>{value.label}</div>
      <DropdownArrow />
    </ClickableContainer>
  );
});

type BasicOptionProps = ClickableContainerProps & {
  text: string,
  highlightEnabled?: boolean
};

const BasicOption = React.forwardRef<HTMLDivElement, BasicOptionProps>((props, ref) => {
  const {
    text,
    highlightEnabled = true,
    ...restProps
  } = props;

  const styles = css`
    align-items: center;
    height: ${doubleSpacing}px;
    padding: 0px ${threeQuartersSpacing}px;
    width: 100%;
    min-width: 0;

    ${highlightEnabled && css`
      &:hover, &:focus {
        background-color: ${info};
      }
    `};
  `;

  return (
    <ClickableContainer
      ref={ref}
      css={styles}
      className='text-regular'
      {...restProps}
    >
      <div className='ellipsis'>{text}</div>
    </ClickableContainer>
  );
});

type PickerOptionProps = Omit<BasicOptionProps, 'text'> & {
  value: LanguageValue,
};

const PickerOption = React.forwardRef<HTMLDivElement, PickerOptionProps>(({
  value,
  ...restProps
}, ref) => (
  <BasicOption
    ref={ref}
    text={value.label}
    {...restProps}
  />
));


const NoResults = () => (
  <BasicOption
    layoutOnly
    highlightEnabled={false}
    text={t.SEARCH.NO_RESULTS_FOUND()}
  />
);

export default LanguagePicker;
