// eslint-disable-next-line max-params
import GlobalComponents from 'components/global-components';
import store from 'redux/store';
import { setShowUpdateEmailAlert } from 'redux/actions/users';
import { LearningCatalogButton } from '../../dashboard/components/learning-catalog-button';

/* @ngInject */
export default function MainGridCtrl(
  $rootScope,
  $scope,
  $state,
  $stateParams,
  $timeout,
  $uibModal,
  $window,
  $element,
  AlertMessages,
  AnnouncementsManager,
  ConversationsManager,
  CountdownTimerManager,
  CurrentCourseManager,
  CurrentPermissionsManager,
  CurrentUserManager,
  DiscussionsManager,
  InvitationsAndRequestsManager,
  LecturePageManager,
  LiveSessionResources,
  NotificationsManager,
  OfflineManager,
  PageLevelManager,
  PusherChannels,
  PusherManager,
  RailsRoutes,
  TimelinesManager,
  _,
  humps,
  moment,
  nvCurrentPage,
  nvUtil,
  $uibModalStack,
  FlyOutPanelManager,
) {
  $scope.GlobalComponents = GlobalComponents;
  $scope.LearningCatalogButton = LearningCatalogButton;

  const vm = this;

  vm.$state = $state;
  vm.$stateParams = $stateParams;

  const toggleNavTimeoutValue = 10;

  vm.popoverId = '';
  vm.isLhsExpandedMobile = false;
  vm.isRhsExpandedMobile = false;
  vm.isLhsExpandedDesktop = false;
  vm.hasRhs = false;
  vm.rhsTitle = '';
  vm.isTopHeaderExpanded = true;
  vm.isAnnouncementsPopoverOpen = false;
  vm.currentUserManager = CurrentUserManager;
  vm.ConversationsManager = ConversationsManager;
  vm.expandedTopHeaderInView = true;
  vm.railsRoutes = RailsRoutes;
  vm.nvCurrentPage = nvCurrentPage;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.AlertMessages = AlertMessages;
  vm.OfflineManager = OfflineManager;
  vm.CountdownTimerManager = CountdownTimerManager;
  vm.DiscussionsManager = DiscussionsManager;
  vm.TimelinesManager = TimelinesManager;
  vm.LecturePageManager = LecturePageManager;
  vm.pendoProperties = { };

  vm.isLhsExpanded = isLhsExpanded;
  vm.shouldHideLHS = shouldHideLHS;
  vm.toggleLhsMobile = toggleLhsMobile;
  vm.toggleLhsDesktop = toggleLhsDesktop;

  vm.hideLhsMobile = hideLhsMobile;
  vm.hideLhsDesktop = hideLhsDesktop;

  vm.showRhsMobile = showRhsMobile;
  vm.showRhsDesktop = showRhsDesktop;

  vm.hideRhsMobile = hideRhsMobile;
  vm.hideRhsDesktop = hideRhsDesktop;

  vm.broadcastLhsScroll = broadcastLhsScroll;

  vm.boolFreezeNavStates = false;

  vm.currentInstitutionAdmin = currentInstitutionAdmin;

  vm.getTotalNotificationsCount = getTotalNotificationsCount;

  vm.shouldShowMenteeButton = shouldShowMenteeButton;
  vm.shouldShowMentorButton = shouldShowMentorButton;

  vm.isModalOrFlyoutOpen = isModalOrFlyoutOpen;
  vm.focusMainPanel = focusMainPanel;
  vm.showSearchIconDivider = showSearchIconDivider;

  // Used by license-dashboard.tsx to hack reloading the current router classes
  vm.getDataClasses = getDataClasses;

  // LHS improvements functionality.
  vm.isOrgAdmin = CurrentPermissionsManager.hasOrgAdminPermissions() || CurrentPermissionsManager.hasCourseManagerPermissions();
  vm.isMentor = CurrentPermissionsManager.hasOrgMentorPermissions();
  vm.isCourseAdmin = CurrentUserManager.isCourseAdminInAtLeastOneCourse();

  $scope.$on('$destroy', () => {
    if (CurrentUserManager.hasLoggedIn()) {
      PusherManager.channelsHash[PusherChannels.announcementsChannel].unbind('new_announcement', newAnnouncementEventHandler);
      PusherManager.currentUserChannel().unbind('announcements_read', announcementsReadEventHandler);
      PusherManager.currentUserChannel().unbind(null, newMessageOrConversationEventHandler);
      PusherManager.currentUserChannel().unbind('live_session_notification_status', liveSessionEventHandler);
      PusherManager.currentUserChannel().unbind('enrolled_in_course', enrolledInCourseHandler);
      PusherManager.currentUserChannel().unbind('unenrolled_from_course', unenrolledInCourseHandler);
    }
  });

  initialize();

  $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
    if (toState.data.level === 4 || (fromState.data.level === 4 && toState.data.level === 4)) {
      vm.boolFreezeNavStates = true;
    }
  });

  $rootScope.$on('$stateChangeStart', () => {
    if (CurrentUserManager.user.hasSystemGeneratedAddress) {
      store.dispatch(setShowUpdateEmailAlert(true));
    }
  });

  $rootScope.$on('$stateChangeSuccess', refetchData);

  function initialize() {
    if (nvCurrentPage.unreadPrivacyPolicy()) {
      const button = {
        text: 'LHS.ACCOUNT_MENU.PRIVACY_POLICY.ALERT.LEARN_MORE',
        onClick: () => $uibModal.open({
          templateUrl: 'shared/templates/privacy-policy-modal.html',
          controller: 'PrivacyPolicyModalCtrl as vm',
          resolve: {
            currentInstitution: nvCurrentPage.getInstitution(),
          },
        }),
      };
      const onClose = () => nvCurrentPage.markUnreadPrivacyPolicyAsRead();
      AlertMessages.info('LHS.ACCOUNT_MENU.PRIVACY_POLICY.ALERT.NOTE', 'LHS.ACCOUNT_MENU.PRIVACY_POLICY.ALERT.PRIVACY_UPDATED', {}, {}, Infinity, button, onClose);
    }
    if (nvCurrentPage.unreadCookiePolicy()) {
      const onClose = () => nvCurrentPage.markUnreadCookiePolicyAsRead();
      AlertMessages.info('LHS.ACCOUNT_MENU.PRIVACY_POLICY.ALERT.NOTE', 'LHS.ACCOUNT_MENU.PRIVACY_POLICY.ALERT.COOKIES_1', {}, {
        privacyLink: ' https://www.novoed.com/privacy-policy/',
        cookieLink: 'https://www.novoed.com/novoed-cookie-policy/',
        tosLink: 'https://www.novoed.com/terms-of-service/',
      }, Infinity, null, onClose);
    }
    if (CurrentUserManager.hasLoggedIn()) {
      PusherManager.setupChannel(PusherChannels.announcementsChannel).bind('new_announcement', newAnnouncementEventHandler);
      PusherManager.currentUserChannel().bind('announcements_read', announcementsReadEventHandler);
      PusherManager.currentUserChannel().bind('new_conversation', newMessageOrConversationEventHandler);
      PusherManager.currentUserChannel().bind('new_message', newMessageOrConversationEventHandler);
      PusherManager.currentUserChannel().bind('live_session_notification_status', liveSessionEventHandler);
      PusherManager.currentUserChannel().bind('enrolled_in_course', enrolledInCourseHandler);
      PusherManager.currentUserChannel().bind('unenrolled_from_course', unenrolledInCourseHandler);

      // Update User Database
      const timezone = moment.tz.guess();
      if (timezone && CurrentUserManager.user.browserTimeZone !== timezone) {
        CurrentUserManager.user.updateAutomaticBrowserTimezone(timezone);
      }
    }
    // set initial state for LHS
    refetchData();
  }

  function getDataClasses() {
    vm.dataClasses = nvCurrentPage.getNonL4Data() || {};

    if (nvCurrentPage.getNonL4Data() && nvCurrentPage.getNonL4Data().headerClass) {
      vm.dataClasses.headerClass = nvCurrentPage.getNonL4Data().headerClass;
    } else {
      vm.dataClasses.headerClass = 'default';
    }

    if (nvCurrentPage.getNonL4Data() && nvCurrentPage.getNonL4Data().mainClass) {
      vm.dataClasses.mainClass = nvCurrentPage.getNonL4Data().mainClass;
    } else {
      vm.dataClasses.mainClass = '';
    }

    if (nvCurrentPage.getNonL4Data() && nvCurrentPage.getNonL4Data().mainPanelClass) {
      vm.dataClasses.mainPanelClass = nvCurrentPage.getNonL4Data().mainPanelClass;
    } else {
      vm.dataClasses.mainPanelClass = '';
    }


    vm.dataClasses.scrollable = nvCurrentPage.getNonL4Data() && nvCurrentPage.getNonL4Data().mainPanelScrollable;

    vm.hasRhs = nvCurrentPage.getNonL4Data() && CurrentCourseManager.course
        && (($state.is('course-home') || $state.is('course-home-edit') || $state.is('program-home'))
          ? CurrentCourseManager.course.showCommunityPanel : true) ? nvCurrentPage.getNonL4Data().hasRhs : false;
    vm.rhsTitle = nvCurrentPage.getNonL4Data() ? nvCurrentPage.getNonL4Data().rhsTitle : '';

    // Programatically checks whether we are in the edit or restricted edit mode to add the appropriate
    // CSS class required by some of our angularjs components
    if ($state.current.parent === 'lecture-page-parent') {
      if ($state.params.rest?.startsWith('edit') || $state.params.rest?.startsWith('restrictedEdit')) {
        vm.dataClasses.mainClass += ' lecture-page-edit';
      }
      if (!$state.params.rest) {
        // Means this is view mode
        vm.dataClasses.mainClass += ' lecture-page-view';
      }
    }
  }

  function setInitialLhsState() {
    if (nvCurrentPage.getPageLevel() < 4 && !vm.boolFreezeNavStates) {
      // vm.isLhsExpandedDesktop = false; // remembering user's latest state; as opposed to overwriting
      vm.isLhsExpandedMobile = false; // closing mobile on state change regardless of user action since it is an overlay on mobile
    } else if (vm.boolFreezeNavStates) {
      vm.boolFreezeNavStates = false;
    }
    // adding new behavior: expanded LHS based on data.expandedLHS property on routes.js
    // as data properties are inherited, we need to check if the data object has its own expandedLHS property.
    vm.isLhsExpandedDesktop = Object.prototype.hasOwnProperty.call($state.current.data, 'expandedLHS');
    // trigger an update of the body class.
    vm.toggleLhsDesktop();
  }

  function setInitialRhsState() {
    if (nvCurrentPage.getPageLevel() < 4 && !vm.boolFreezeNavStates) {
      vm.isRhsExpandedMobile = false; // closing mobile on state change regardless of user action since it is an overlay on mobile
    }
  }

  function getLiveEvents() {
    LiveSessionResources.activeFlashNotifications()
      .$promise.then((response) => {
        response.result.forEach((note) => liveSessionEventHandler(note));
      });
  }

  function refetchData() {
    setInitialLhsState();
    setInitialRhsState();
    getDataClasses();
    getLiveEvents();
    setPendoProperties();
  }

  function setPendoProperties() {
    const currentCourse = CurrentUserManager.coursesHashByCatalogId[$state.params.catalogId];
    if (!currentCourse) {
      vm.pendoProperties = {};
    } else {
      vm.pendoProperties = _.extend(
        currentCourse.getPendoProperties(),
        CurrentPermissionsManager.getPendoProperties(),
      );
    }
  }

  function shouldHideLHS() {
    return (
      $state.is('learning-journey-basics')
      && !CurrentPermissionsManager.hasCourseManagerPermissions()
    );
  }

  function isLhsExpanded() {
    return vm.isLhsExpandedMobile || vm.isLhsExpandedDesktop;
  }

  // yes I know these functions break the DRY principle but it's what happens when Design wants non-responsive forking behavior
  function toggleLhsMobile() {
    // give opening and closing LHS a nontrivial timeout so the popovers can close first and not look funny
    $timeout(() => {
      vm.isLhsExpandedMobile = !vm.isLhsExpandedMobile;

      // Update a class for body, so that elements like flyout can understand
      // whether lhs is expanded or not
      if (vm.isLhsExpandedMobile) {
        angular.element('body').addClass('mobile-lhs-expanded');
      } else {
        angular.element('body').removeClass('mobile-lhs-expanded');
      }
      // vm.isLhsExpandedDesktop = vm.isLhsExpandedMobile;

      // Set focus to inside of the LHS panel
      const parentElement = $element.find('#mobile-left-nav')?.[0];
      if (parentElement) {
        nvUtil.focusFirstElement(parentElement);
      }
    }, toggleNavTimeoutValue);
  }

  function toggleLhsDesktop() {
    // give opening and closing LHS a nontrivial timeout so the popovers can close first and not look funny
    $timeout(() => {
      // Update a class for body, so that elements like flyout can understand
      // whether lhs is expanded or not
      if (vm.isLhsExpandedDesktop) {
        angular.element('body').addClass('desktop-lhs-expanded');
      } else {
        angular.element('body').removeClass('desktop-lhs-expanded');
      }
      // vm.isLhsExpandedMobile = vm.isLhsExpandedDesktop;
    }, toggleNavTimeoutValue);
  }

  function hideLhsMobile() {
    $timeout(() => {
      vm.isLhsExpandedMobile = false;
    }, toggleNavTimeoutValue);
  }

  function hideLhsDesktop() {
    $timeout(() => {
      vm.isLhsExpandedDesktop = false;
    }, toggleNavTimeoutValue);
  }

  function showRhsMobile() {
    // give opening and closing RHS a nontrivial timeout so the popovers can close first and not look funny
    $timeout(() => {
      vm.isRhsExpandedMobile = true;
    }, toggleNavTimeoutValue);
  }

  function showRhsDesktop() {
    // give opening and closing RHS a nontrivial timeout so the popovers can close first and not look funny
    $timeout(() => {
      vm.isRhsExpandedDesktop = true;
    }, toggleNavTimeoutValue);
  }

  function hideRhsMobile() {
    // give opening and closing RHS a nontrivial timeout so the popovers can close first and not look funny
    $timeout(() => {
      vm.isRhsExpandedMobile = false;
    }, toggleNavTimeoutValue);
  }

  function hideRhsDesktop() {
    // give opening and closing RHS a nontrivial timeout so the popovers can close first and not look funny
    $timeout(() => {
      vm.isRhsExpandedDesktop = false;
    }, toggleNavTimeoutValue);
  }

  function showSearchIconDivider() {
    /**
     * Always show the LHS section divider below the search icon in not expanded view
     * In expanded view if there is courses then 'Courses' heading will show instead of the divider.
     * If it is in institution dashboard, 'Admin Controls' heading will show instead of the divider.
     */
    return (!vm.isLhsExpandedDesktop
      || (vm.isLhsExpandedDesktop
          && vm.currentUserManager.courses.length === 0
          && vm.$state.current.name !== 'institution-dashboard'));
  }

  function broadcastLhsScroll() {
    $scope.$broadcast('lhsScroll');
  }

  function currentInstitutionAdmin() {
    const institution = nvCurrentPage.getCurrentWalledGardenInstitution();
    let institutionId;

    if (institution?.isInstitutionalAdmin) {
      return institution;
    } if ($state.params.catalogId && CurrentUserManager.coursesHashByCatalogId && CurrentUserManager.coursesHashByCatalogId[$state.params.catalogId]) {
      institutionId = CurrentUserManager.coursesHashByCatalogId[$state.params.catalogId].institution.id;
    } else {
      ({ institutionId } = $state.params);
    }

    return CurrentUserManager.getInstitutionAdmin(institutionId);
  }

  const debounced = _.debounce(() => {
    if (!$scope.$$phase) {
      $scope.$apply();
    }
  }, 100);

  angular.element($window).on('resize', debounced);
  $scope.$on('$destroy', () => {
    angular.element($window).off('resize', debounced);
  });

  // Private Functions
  function enrolledInCourseHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    CurrentUserManager.refreshUserCourse(pusherData.course.catalogId);
  }

  function unenrolledInCourseHandler(pusherData) {
    CurrentUserManager.removeCourse(pusherData.course);
  }

  function newAnnouncementEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);
    AnnouncementsManager.newAnnouncement(pusherData.id, pusherData.courseId);
  }

  function announcementsReadEventHandler(data) {
    // we don't care about read notifications. would be done by the same user in another session
  }

  function newMessageOrConversationEventHandler() {
    ConversationsManager.initialize();
  }

  function getTotalNotificationsCount() {
    return CurrentCourseManager.course
      && AnnouncementsManager.numUnreadAnnouncements
      + NotificationsManager.numUnreadNotifications
      + InvitationsAndRequestsManager.numItems
      + ConversationsManager.numUnreadMessages;
  }

  // show this button only for L1 pages, we will special case L2s and L3s with sticky footers
  // Shows the button for viewing the list of assigned mentees
  // TODO: This needs updated for the new architecture
  function shouldShowMenteeButton() {
    return !$state.includes('mentees')
        && ($state.includes('course') || $state.params.catalogId)
        && (
          ($state.current.data.level === 3.5 ? (FlyOutPanelManager.lastNonFlyoutState.state.includes.course !== undefined) : (
            nvCurrentPage.getPageLevel() === 1
            || nvCurrentPage.getPageLevel() === 2
            || (nvCurrentPage.getCurrentStateData().level === 4 && PageLevelManager.lastParent().state.data.level === 1 && PageLevelManager.lastParent().state.name !== 'mentees')
          ))
        )
        && CurrentPermissionsManager.isMentor()
        // No need to display the MenteeButton in edit modes
        && !(vm.TimelinesManager.isEditMode() || vm.TimelinesManager.isReorderMode()
          || vm.LecturePageManager.isEditMode() || vm.LecturePageManager.isRestrictedEditMode() || vm.LecturePageManager.isReorderMode());
  }

  // Shows the button for viewing the list of this user's mentors
  function shouldShowMentorButton() {
    return ($state.is('course-home') || $state.is('program-home')) && CurrentCourseManager.course?.userCourse?.mentors?.length;
  }

  // Live Sessions Notifications
  function liveSessionEventHandler(pusherDataSnaked) {
    const {
      joinUrl, catalogId, liveSessionId, eventName, endTime,
    } = humps.camelizeKeys(pusherDataSnaked);

    let requestedJoin = false;
    const duration = moment(endTime) - moment();
    const button = {
      text: 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.JOIN',
      href: joinUrl,
      target: '_blank',
      onClick: () => {
        requestedJoin = true;
        LiveSessionResources.recordJoinIntention({ catalogId, liveSessionId }, {});
      },
    };

    const onClose = () => {
      if (!requestedJoin) {
        LiveSessionResources.dismissNotification({ catalogId, liveSessionId }, {});
      }
    };
    const courseName = CurrentUserManager.courses.find((course) => course.catalogId === catalogId).name;
    AlertMessages.liveEvent('', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.HAS_STARTED', {}, { eventName, courseName }, duration, button, onClose, `liveEvent-${liveSessionId}`);
  }

  /**
   * Check whether current state is flyout or modal.
   * Used for adding area-hidden attribute to prevent screen readers
   * reading the main panel when flyout or modal opened
   * @returns boolian
   */
  function isModalOrFlyoutOpen() {
    return $state.includes('modal-page') || $state.includes('flyout-panel') || $uibModalStack.getTop();
  }

  /**
   * Function that find the first focusable element in the main
   * content and focus it when keyboard only use click
   * Skip to content button
   */
  function focusMainPanel() {
    let mainContentId = '#main-panel';
    if ($state.includes('lecture-page')) {
      mainContentId = '#lecture-page-main-content';
    } else if ($state.includes('course-home')) {
      mainContentId = '#main-content';
    }
    const parentElement = $element.find(mainContentId)?.[0];
    if (parentElement) {
      nvUtil.focusFirstElement(parentElement);
    }
  }

  if (CurrentUserManager.user.i18nFteLang) {
    const modalInstance = $uibModal.open({
      templateUrl: 'shared/templates/i18n-modal.html',
      windowClass: 'points-modal mobile-non-blocking super-modal',
      openedClass: 'super-modal',
      controller: 'AttachModalResolvesToVmCtrl as vm',
      keyboard: false,
      resolve: {
        vmResolves() {
          return {
            langKey: CurrentUserManager.user.i18nFteLang,
          };
        },
      },
    });

    modalInstance.closed.then(() => {
      CurrentUserManager.user.updateHasViewedI18nFte();
    });
  }

  $scope.$on('setPopoverId', (event, popoverId) => {
    if (event.targetScope.popoverVm.showPopover) {
      vm.popoverId = popoverId;
    } else if (vm.popoverId === event.targetScope.popoverVm.popoverId) {
      vm.popoverId = popoverId;
    }
  });
}
